import { Component, input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { htmlToPureText } from 'app/utils/html-to-pure-text.util';
import { Editor, toHTML, Toolbar } from 'ngx-editor';
import {} from 'ngx-editor/schema';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'hmt-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent implements OnInit, OnDestroy {
  editor: Editor;

  outputAs = input<'html' | 'doc'>('html');
  formGroup = input<FormGroup>(null);
  control = input<FormControl<string>>(null);
  controlName = input<string>(null);
  outputString = input<string>();
  placeholder = input<string>('Type here...');
  disabled = input<boolean>(false);
  label = input<string>(null);
  boldLabel = input(false);
  labelStyles = input<string | null>(null);

  characterLimit = input<number | null>(1000);
  characterCount = 0;
  showCharacterLimitError = false;

  unsubscribe$ = new Subject<void>();

  toolbar = input<Toolbar>([
    // default value
    ['bold', 'italic', 'underline', 'strike', 'text_color'],
    ['blockquote', { link: { showOpenInNewTab: true } }],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right', 'align_justify', 'indent', 'outdent', 'superscript', 'subscript'],
    // ['indent', 'outdent'],
    // ['superscript', 'subscript'],
  ]);

  ngOnInit(): void {
    if (!this.formGroup() && (this.control() || this.controlName())) {
      throw new Error('formGroup is required when control or controlName is provided');
    }

    if (this.formGroup() && !this.control() && !this.controlName()) {
      throw new Error('control or controlName is required when formGroup is provided');
    }

    if (this.formGroup() && this.outputString()) {
      throw new Error('outputString is not allowed when formGroup is provided');
    }

    this.editor = new Editor({});

    this.editor.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.characterCount = this.getCharacterCount(toHTML(value));
      this.showCharacterLimitError = this.characterLimit() !== null && this.characterCount > this.characterLimit();
    });
  }

  isDisabled() {
    return (
      this.disabled() ||
      this.formGroup()?.disabled ||
      this.control()?.disabled ||
      this.formGroup()?.controls[this.controlName()]?.disabled
    );
  }

  ngOnDestroy(): void {
    this.editor.destroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  htmlToPureText(html: string) {
    return htmlToPureText(html).trim();
  }

  getCharacterCount(html: string) {
    return this.htmlToPureText(html).length;
  }
}
