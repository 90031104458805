import { Component, inject, input, OnDestroy, OnInit, viewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { WeightUnits } from '@shared/enums/weight-units.enum';
import { CreateLongTermTenderBidService } from 'app/modules/bidding-wizard/services/create-long-term-tender-bid.service';
import {
  DEFAULT_AIR_FREIGHT_LCL_VOLUME_RATES,
  DEFAULT_AIR_FREIGHT_LCL_VOLUME_WEIGHTS,
  DEFAULT_OCEAN_FREIGHT_LCL_VOLUME_RATES,
  DEFAULT_OCEAN_FREIGHT_LCL_VOLUME_WEIGHTS,
  GREATER_THAN_COMPARISON_OPERATOR,
} from 'app/modules/procurement/constants/lcl-rates';
import { Subject, takeUntil } from 'rxjs';

export interface WeightCell {
  comparison: string;
  value: number;
}

export interface RateCell {
  rate: number;
}

@Component({
  selector: 'hmt-carrier-schedule-lcl-charging-structure',
  templateUrl: './carrier-schedule-lcl-charging-structure.component.html',
  styleUrls: ['./carrier-schedule-lcl-charging-structure.component.scss'],
})
export class CarrierScheduleLclChargingStructureComponent implements OnInit, OnDestroy {
  private readonly createLongTermTenderBidService = inject(CreateLongTermTenderBidService);
  private readonly fb = inject(FormBuilder);

  isVisible = input.required<boolean>();
  isDisabled = input<boolean>(false);
  disableDefaultColumns = input<boolean>(false);
  isReadOnly = input<boolean>(false);
  isAirFreight = input<boolean>(false);
  doesRateStructureExist = input<boolean>(false);

  destroy = new Subject<void>();

  currencies$ = this.createLongTermTenderBidService.getAllCurrencies().pipe(takeUntil(this.destroy));

  WeightUnits = WeightUnits;

  displayedWeightColumns = ['volumeWeight'];
  weightColumns: WeightCell[] = [];
  rateColumns: RateCell[] = [];

  ratesForm = this.fb.group({
    columns: this.fb.array<
      FormGroup<{
        comparison: FormControl<string>;
        value: FormControl<number>;
      }>
    >([]),
    rates: this.fb.array<
      FormGroup<{
        rate: FormControl<number>;
      }>
    >([]),
    additionalCharges: new FormArray([
      new FormGroup({
        chargeType: this.fb.control<string | null>(null, [Validators.required]),
        chargeAmount: this.fb.control<number | null>(null, [Validators.required]),
      }),
    ]),
    mainCurrency: this.fb.control<string | null>(null, [Validators.required]),
  });

  ratesTable = viewChild<MatTable<unknown>>('ratesTable');

  ngOnInit(): void {
    if (!this.doesRateStructureExist()) {
      if (this.isAirFreight()) {
        this.weightColumns = DEFAULT_AIR_FREIGHT_LCL_VOLUME_WEIGHTS.map(weight => {
          return {
            comparison: weight >= 0 ? '>' : '<',
            value: Math.abs(weight),
          };
        });
        this.rateColumns = DEFAULT_AIR_FREIGHT_LCL_VOLUME_RATES.map(rate => {
          return {
            rate,
          };
        });
      } else {
        this.weightColumns = DEFAULT_OCEAN_FREIGHT_LCL_VOLUME_WEIGHTS.map(weight => {
          return {
            comparison: weight >= 0 ? '>' : '<',
            value: Math.abs(weight),
          };
        });

        this.rateColumns = DEFAULT_OCEAN_FREIGHT_LCL_VOLUME_RATES.map(rate => {
          return {
            rate,
          };
        });
      }
    }

    this.initializeColumns();
    this.updateDisplayedColumns();
  }

  private initializeColumns() {
    const columnsArray = this.ratesForm.get('columns') as FormArray<
      FormGroup<{
        comparison: FormControl<string>;
        value: FormControl<number>;
      }>
    >;
    const ratesArray = this.ratesForm.get('rates') as FormArray<
      FormGroup<{
        rate: FormControl<number>;
      }>
    >;

    if (!this.disableDefaultColumns()) {
      this.weightColumns.forEach(column => {
        columnsArray.push(
          this.fb.group({
            comparison: [column.comparison],
            value: [column.value],
          })
        );
      });

      this.rateColumns.forEach(column => {
        ratesArray.push(this.fb.group({ rate: [column.rate] }));
      });
    }
  }

  addColumn() {
    const columnsArray = this.ratesForm.get('columns') as FormArray<
      FormGroup<{
        comparison: FormControl<string>;
        value: FormControl<number>;
      }>
    >;
    columnsArray.push(
      this.fb.group({
        comparison: new FormControl<string>('>', Validators.required),
        value: new FormControl<number | null>(null, Validators.required),
      })
    );

    const ratesArray = this.ratesForm.get('rates') as FormArray<
      FormGroup<{
        rate: FormControl<number>;
      }>
    >;
    ratesArray.push(
      this.fb.group({
        rate: [null],
      })
    );
    this.updateDisplayedColumns();
  }

  removeColumn(index: number) {
    const columnsArray = this.ratesForm.get('columns') as FormArray;
    columnsArray.removeAt(index);

    const ratesWeightsArray = this.ratesForm.get('rates') as FormArray;
    ratesWeightsArray.removeAt(index);

    if (this.isAirFreight() && columnsArray.length === 1) {
      const lastColumn = columnsArray.at(0) as FormGroup;
      lastColumn.get('comparison')?.setValue(GREATER_THAN_COMPARISON_OPERATOR);
      lastColumn.get('value')?.setValue(0);
    }

    this.updateDisplayedColumns();
  }

  private updateDisplayedColumns() {
    this.displayedWeightColumns = ['volumeWeight'];
    const columnsArray = this.ratesForm.get('columns') as FormArray;
    for (let i = 0; i < columnsArray.length; i++) {
      this.displayedWeightColumns.push('weight_' + i);
    }
  }

  get columnsFormArray() {
    return this.ratesForm.get('columns') as FormArray<
      FormGroup<{
        comparison: FormControl<string>;
        value: FormControl<number>;
      }>
    >;
  }

  get ratesFormArray() {
    return this.ratesForm.get('rates') as FormArray<
      FormGroup<{
        rate: FormControl<number>;
      }>
    >;
  }

  addAdditionalCharge() {
    this.ratesForm.controls.additionalCharges.push(
      this.fb.group({
        chargeType: this.fb.control<string | null>(null, [Validators.required]),
        chargeAmount: this.fb.control<number | null>(null, [Validators.required]),
      })
    );
  }

  removeAdditionalCharge(index: number) {
    this.ratesForm.controls.additionalCharges.removeAt(index);
  }

  getFormValue() {
    return this.ratesForm.valid ? this.ratesForm.value : null;
  }

  setFormValue(value: {
    columns: {
      comparison: string;
      value: number;
    }[];
    rates: {
      rate: number;
    }[];
    additionalCharges: {
      chargeType: string;
      chargeAmount: number;
    }[];
    mainCurrency: string;
  }) {
    this.ratesForm.controls.columns.clear();
    this.ratesForm.controls.rates.clear();
    this.ratesForm.controls.additionalCharges.clear();
    this.ratesForm.controls.mainCurrency.setValue(value.mainCurrency);

    value.columns.forEach(column => {
      this.ratesForm.controls.columns.push(this.fb.group({ comparison: [column.comparison], value: [column.value] }));
    });

    value.rates.forEach(rate => {
      this.ratesForm.controls.rates.push(this.fb.group({ rate: [rate.rate] }));
    });

    value.additionalCharges.forEach(charge => {
      this.ratesForm.controls.additionalCharges.push(
        this.fb.group({ chargeType: [charge.chargeType], chargeAmount: [charge.chargeAmount] })
      );
    });
    this.updateDisplayedColumns();
    this.ratesForm.updateValueAndValidity({
      emitEvent: true,
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
