import { gql } from 'apollo-angular';
import { UploadedFileType } from 'app/modules/wizard/models/uploaded-file.type';
import { OrgDocuments } from '../models/org-documents.model';

export const ADD_DOCUMENTS_TO_ORG_DOCUMENT_CATEGORY_QUERY = gql`
  mutation AddDocumentToOrgDocumentCategory(
    $addDocumentToOrgDocumentCategoryInput: AddDocumentToOrgDocumentCategoryDto!
  ) {
    addDocumentToOrgDocumentCategory(addDocumentToOrgDocumentCategoryInput: $addDocumentToOrgDocumentCategoryInput) {
      _id
      createdAt
      createdBy
      orgId
      updatedAt
      updatedBy
    }
  }
`;

export interface AddDocumentToOrgDocumentCategoryDto {
  addDocumentToOrgDocumentCategory: OrgDocuments;
}

export interface AddDocumentToOrgDocumentCategoryInputDto {
  addDocumentToOrgDocumentCategoryInput: {
    categoryId: string;
    item: UploadedFileType;
    orgId: string;
  };
}
