import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { Apollo, gql } from 'apollo-angular';
import { Organization } from 'app/modules/organizations-manager/models/organization.model';
import { catchError, map, of, tap, throwError } from 'rxjs';
import {
  SetCurrentOperation,
  SetLoggedInUsersOrganization,
  SetOperations,
  SetRecentStep,
  SetYouAreHere,
} from './app.actions';
import { UserOperation, YouAreHere } from './app.model';

export class AppStateModel {
  public operations: UserOperation[];
  public currentOperation: UserOperation;
  public youAreHere: YouAreHere;
  public recentStep: {
    _id: string;
    url: string;
    urlParams: any;
  } | null;
  public loggedInUsersOrganization: Organization;
}

const defaults: AppStateModel = {
  operations: [],
  currentOperation: null,
  recentStep: null,
  youAreHere: null,
  loggedInUsersOrganization: null,
};

@State<AppStateModel>({
  name: 'app',
  defaults,
})
@Injectable()
export class AppState {
  constructor(
    private store: Store,
    private apollo: Apollo
  ) {}

  @Selector()
  static getOperations(state: AppStateModel) {
    return state.operations;
  }

  @Selector()
  static getCurrentOperationIds(state: AppStateModel): string[] {
    if (state.currentOperation.name === 'All') {
      return state.operations.map(operation => operation._id);
    }
    return [state.currentOperation._id];
  }

  @Selector()
  static getCurrentOperationOrAll(state: AppStateModel): UserOperation | 'All' {
    if (state.currentOperation.name === 'All') {
      return 'All';
    }

    return state.operations.find(operation => operation._id === state.currentOperation._id) || null;
  }

  @Selector()
  static getCurrentOperation(state: AppStateModel): UserOperation | 'All' {
    return state.currentOperation;
  }

  @Selector()
  static getCurrentOperationId(state: AppStateModel): string {
    if (state.currentOperation.name == 'All') {
      return null;
    }

    return state.currentOperation._id;
  }

  @Selector()
  static recentStep(state: AppStateModel) {
    return state.recentStep;
  }

  @Selector()
  static getYouAreHere(state: AppStateModel) {
    return state.youAreHere;
  }

  @Selector()
  static onlyFreightForwarder(state: AppStateModel) {
    return state.loggedInUsersOrganization?.onlyFreightForwarder ?? false;
  }

  @Action(SetOperations)
  SetOperations({ patchState }: StateContext<AppStateModel>) {
    const {
      user: { operations },
    } = this.store.selectSnapshot(state => state.auth);

    if (!operations || operations.length == 0) {
      return of(null);
    }

    const operationIdsString = operations.map(id => `"${id}"`).join(', ');

    return this.apollo
      .query<{ getOperationsByIds: UserOperation[] }>({
        query: gql`
      query getOperationsByIds {
        getOperationsByIds(
          operationIds: [
            ${operationIdsString}
          ]
        ) {
          _id
          name
        }
      }
    `,
      })
      .pipe(
        map(result => result.data.getOperationsByIds),
        tap(operationsData => {
          patchState({
            operations: operationsData, // Update only the operations in the state
          });
        })
      );
  }

  @Action(SetCurrentOperation)
  setCurrentOperation({ patchState }: StateContext<AppStateModel>, action: SetCurrentOperation) {
    patchState({
      currentOperation: action.currentOperation,
    });
  }

  @Action(SetRecentStep)
  setRecentStep(ctx: StateContext<AppStateModel>, action: SetRecentStep) {
    const GET_RECENT_STEP = gql`
      query GetRecentStep($jobRefId: String!) {
        getRecentStep(jobRefId: $jobRefId) {
          _id
          url
        }
      }
    `;

    return this.apollo
      .query<{ getRecentStep: AppStateModel['recentStep'] }>({
        query: GET_RECENT_STEP,
        variables: { jobRefId: action.jobRefId },
      })
      .pipe(
        map(result => result.data.getRecentStep),
        tap(recentStep => {
          ctx.patchState({
            recentStep,
          });
        }),
        catchError(error => {
          console.error('Error fetching recent step:', error);
          return throwError(() => error);
        })
      );
  }

  @Action(SetYouAreHere)
  setYouAreHere({ patchState }: StateContext<AppStateModel>, action: SetYouAreHere) {
    if (!action.orgId) {
      return of(null);
    }
    return this.apollo
      .mutate<{ getYouAreHere: YouAreHere }>({
        mutation: gql`
          mutation GetYouAreHere($workflowId: String!, $jobRefId: String!, $orgId: String!) {
            getYouAreHere(workflowId: $workflowId, jobRefId: $jobRefId, orgId: $orgId) {
              _id
              workflowId
              jobRefId
              step {
                enabled
                url
                name
                subStep {
                  tagName
                  name
                  enabled
                  url
                }
              }
            }
          }
        `,
        variables: {
          workflowId: action.workFlowId,
          jobRefId: action.jobRefId,
          orgId: action.orgId,
        },
      })
      .pipe(
        tap(result => {
          if (result.data) {
            patchState({ youAreHere: result.data.getYouAreHere });
          }
        }),
        catchError(error => {
          console.error('Error fetching YouAreHere:', error);
          return of(null);
        })
      );
  }

  @Action(SetLoggedInUsersOrganization)
  setLoggedInUsersOrganization({ patchState }: StateContext<AppStateModel>, action: SetLoggedInUsersOrganization) {
    return this.apollo
      .query<{ getOrganizationById: Organization }>({
        query: gql`
          query GetOrganizationById($orgId: String!) {
            getOrganizationById(orgId: $orgId) {
              _id
              onlyFreightForwarder
            }
          }
        `,
        variables: { orgId: action?.organizationId },
      })
      .pipe(
        map(result => result.data.getOrganizationById),
        tap(organization => {
          patchState({ loggedInUsersOrganization: organization });
        })
      );
  }
}
