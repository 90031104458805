import { GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY } from '@configs/constants';
import { gql } from 'apollo-angular';

export const GET_ORGANIZATION_NAME_BY_ID_QUERY = gql`
  query GetOrganizationNameById($orgId: String!) {
    getOrganizationById(orgId: $orgId) {
      ${GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY}
    }
  }
`;

export type GetOrganizationNameByIdDTO = {
  getOrganizationById: {
    _id: string;
    orgName: string;
  };
};
