import { gql } from 'apollo-angular';
import { OrgDocuments } from '../models/org-documents.model';

export const REMOVE_ORG_DOCUMENT_CATEGORY_QUERY = gql<RemoveOrgDocumentCategoryDto, RemoveOrgDocumentCategoryInputDto>`
  mutation RemoveOrgDocumentCategory($removeOrgDocumentCategoryInput: RemoveOrgDocumentCategoryDto!) {
    removeOrgDocumentCategory(removeOrgDocumentCategoryInput: $removeOrgDocumentCategoryInput) {
      _id
      createdAt
      createdBy
      updatedAt
      orgId
      updatedBy
      categories {
        _id
        createdAt
        createdBy
        deleted
        name
        updatedAt
        updatedBy
        documents {
          customFileName
          fileId
          fileName
          fileType
          isActive
          isUploaded
          orgUserId
          uploadedDate
          uploadedByName
        }
      }
    }
  }
`;

export interface RemoveOrgDocumentCategoryDto {
  removeOrgDocumentCategory: OrgDocuments;
}

export interface RemoveOrgDocumentCategoryInputDto {
  removeOrgDocumentCategoryInput: {
    categoryId: string;
    orgId: string;
  };
}
