import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { htmlToPureText } from './html-to-pure-text.util';

export const characterLimitValidatorForRichTextEditor = (maxCharacterLimit: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value || typeof value !== 'string') {
      return null;
    }

    const characterCount = getCharacterCount(value);
    return characterCount > maxCharacterLimit
      ? { characterLimit: { value: characterCount, max: maxCharacterLimit } }
      : null;
  };
};

function getCharacterCount(html: string) {
  return htmlToPureText(html).trim().length;
}
