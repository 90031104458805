import { GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY } from '@configs/constants';
import { Pageable } from '@shared/models/pageable.model';
import { gql } from 'apollo-angular';
import { Organization } from '../models/organization.model';

// TODO: ADD OTHER NECESSARY FIELDS AS WELL
export const GET_PAGINATED_COMMON_ORGANIZATIONS_QUERY = gql`
  query GetPaginatedCommonOrganizations($getPaginatedCommonOrganizationsInput: GetPaginatedPartnerOrganizationsDto!) {
    getPaginatedCommonOrganizations(getPaginatedCommonOrganizationsInput: $getPaginatedCommonOrganizationsInput) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageSize
        total
      }
      items {
        ${GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY}
      }
    }
  }
`;

export type GetPaginatedCommonOrganizationsDTO = {
  getPaginatedCommonOrganizations: Pageable<Organization>;
};
