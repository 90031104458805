import { GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY } from '@configs/constants';
import { gql } from 'apollo-angular';
import { Organization } from '../models/organization.model';

// TODO: ADD OTHER NECESSARY FIELDS AS WELL
export const FIND_ORGANIZATIONS_BY_VERTICAL_ID_AND_MANAGED_BY_QUERY = gql`
  query findOrganizationsByVerticalIdAndManagedBy($findOrganizationsByVerticalIdAndManagedByInput: FindOrganizationsByVerticalIdAndManagedByDto!) {
    findOrganizationsByVerticalIdAndManagedBy(findOrganizationsByVerticalIdAndManagedByInput: $findOrganizationsByVerticalIdAndManagedByInput) {
      ${GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY}
    }
  }
`;

export type FindOrganizationsByVerticalIdAndManagedByDTO = {
  findOrganizationsByVerticalIdAndManagedBy: Organization[];
};
