<div [class.error-editor]="showCharacterLimitError">
  <div>
    @if (label() && labelStyles()) {
      <div [class.bold]="boldLabel()" [ngClass]="labelStyles()">{{ label() }}</div>
    } @else if (label() && !labelStyles()) {
      <div class="label" [class.bold]="boldLabel()">{{ label() }}</div>
    }

    @if (formGroup()) {
      <div class="NgxEditor__Wrapper" [formGroup]="formGroup()">
        @if (!isDisabled()) {
          <ngx-editor-menu [editor]="editor" [toolbar]="toolbar()"> </ngx-editor-menu>
        }
        @if (controlName()) {
          <ngx-editor
            [editor]="editor"
            [formControlName]="controlName()"
            [placeholder]="placeholder()"
            [outputFormat]="outputAs()"></ngx-editor>
        } @else if (control()) {
          <ngx-editor
            [editor]="editor"
            [formControl]="control()"
            [placeholder]="placeholder()"
            [outputFormat]="outputAs()"></ngx-editor>
        } @else {
          <ngx-editor
            [editor]="editor"
            [ngModel]="outputString()"
            [placeholder]="placeholder()"
            [disabled]="isDisabled()"
            [outputFormat]="outputAs()"></ngx-editor>
        }
      </div>
    } @else {
      <div class="NgxEditor__Wrapper">
        <ngx-editor
          [editor]="editor"
          [ngModel]="outputString()"
          [placeholder]="placeholder()"
          [disabled]="isDisabled()"
          [outputFormat]="outputAs()"></ngx-editor>
      </div>
    }

    @if (characterLimit() && !showCharacterLimitError && !isDisabled()) {
      <div class="character-limit">{{ characterLimit() - characterCount }} characters left</div>
    }

    @if (characterLimit() && showCharacterLimitError && !isDisabled()) {
      <div class="character-limit character-limit-error">
        {{ characterCount - characterLimit() }} characters over limit
      </div>
    }
  </div>
</div>
