import { gql } from 'apollo-angular';
import { OrganizationNode, OrganizationTree } from '../models/organization-node.model';

export const GET_ORGANIZATION_STRUCTURE_QUERY = gql`
  query GetOrganizationStructure {
    getOrganizationStructure {
      self {
        _id
        companyRegNum
        companyWebSite
        createdBy
        epfNumber
        etfNumber
        logo
        managedBy
        opsConfig {
          currencies {
            _id
            code
            createdBy
            currency
            shortCode
            updatedBy
          }
          defaultCurrency {
            _id
            code
            createdBy
            currency
            shortCode
            updatedBy
          }
          defaultLanguage {
            _id
            createdBy
            isoCode
            name
            updatedBy
            writingDirection
          }
          defaultPaymentMethod {
            paymentMethod {
              _id
              createdBy
              logo
              name
              updatedBy
            }
          }
          languages {
            _id
            createdBy
            isoCode
            name
            updatedBy
            writingDirection
          }
          paymentMethods {
            paymentMethod {
              _id
              createdBy
              logo
              name
              updatedBy
            }
          }
        }
        organizationFunctionalityType
        orgName
        orgRegisteredType
        orgSize
        orgType
        paidCustomer
        parentOrgId
        parentOrgName
        party
        principalContact {
          designation
          division
          email
          faxNumber
          firstName
          gender
          lastName
          mobile
          nic
          orgUserId
          officeContact {
            ext
            phoneNumber
          }
        }

        registeredAddress {
          addressLine
          city
          country
          state
          timeZone {
            label
            tzCode
            name
            utc
          }
          zipCode
        }

        shortName
        status
        tenant
        tickerCode
        tinNum
        updatedBy
        vatNum
        verticals
        phoneNumbers
        faxNumbers
      }
      children
      id
      isRoot
    }
  }
`;

export type GetOrganizationStructureDto = {
  getOrganizationStructure: OrganizationNode[];
};

export function convertToTree(tags: OrganizationNode[]): OrganizationTree | null {
  // Step 1: Create a Map to store all nodes
  const nodeMap: Record<string, OrganizationNodeWithParent> = {};

  // Step 2: Populate the Map
  for (const node of tags) {
    nodeMap[node.id] = node;
  }

  // Step 3: Find the root node
  let rootNode: OrganizationNode | undefined;
  for (const node of Object.values(nodeMap)) {
    if (node.isRoot) {
      rootNode = node;
      break;
    }
  }

  if (!rootNode) {
    return null; // No root found
  }

  // Step 4: Build the tree structure
  for (let node of Object.values(nodeMap)) {
    if (node.id !== rootNode.id) {
      for (const parentNode of Object.values(nodeMap)) {
        if (parentNode.children.includes(node.id)) {
          node = { ...node, parent: parentNode };
          break;
        }
      }
    }
  }

  // Step 5: Convert to OrganizationTree
  function _convertToTree(node: OrganizationNode): OrganizationTree {
    return {
      self: node.self,
      children: node.children
        .map(id => {
          const childNode = nodeMap[id];
          return childNode ? _convertToTree(childNode) : null;
        })
        .filter(Boolean) as OrganizationTree[],
    };
  }

  return _convertToTree(rootNode);
}

// Helper type for internal use during conversion
interface OrganizationNodeWithParent extends OrganizationNode {
  parent?: OrganizationNode;
}
