import { Component, computed, inject, input, OnDestroy, OnInit, output, Signal, viewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { WeightUnits } from '@shared/enums/weight-units.enum';
import {
  RateCell,
  WeightCell,
} from 'app/modules/catalog-manager/components/carrier-schedule-lcl-charging-structure/carrier-schedule-lcl-charging-structure.component';
import {
  GREATER_THAN_COMPARISON_OPERATOR,
  LESS_THAN_COMPARISON_OPERATOR,
} from 'app/modules/procurement/constants/lcl-rates';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'hmt-lcl-charging-structure-v2',
  templateUrl: './lcl-charging-structure-v2.component.html',
  styleUrl: './lcl-charging-structure-v2.component.scss',
})
export class LclChargingStructureV2Component implements OnInit, OnDestroy {
  private readonly destroy = new Subject<void>();
  private readonly fb = inject(FormBuilder);

  isVisible = input.required<boolean>();
  disableDefaultColumns = input<boolean>(false);
  isReadOnly = input<boolean>(false);
  isAirFreight = input<boolean>(false);
  inputWeights = input<number[]>([]);
  inputRates = input<number[]>([]);
  WeightUnits = WeightUnits;
  styles = input<string>('');
  maxWidth = input<string>('');

  weightColumns: Signal<WeightCell[]> = computed(() => this.generateWeightColDataBasedOnProvidedInputs());
  rateColumns: Signal<RateCell[]> = computed(() => this.generateRateColDataBasedOnProvidedInputs());

  tableDataUpdateEvent = output<{
    weights: number[];
    rates: number[];
    weightUnit: WeightUnits;
  }>();

  displayedWeightColumns = ['volumeWeight'];

  ratesForm = this.fb.group({
    columns: this.fb.array<
      FormGroup<{
        comparison: FormControl<string>;
        value: FormControl<number>;
      }>
    >([]),
    rates: this.fb.array<
      FormGroup<{
        rate: FormControl<number>;
      }>
    >([]),
  });

  ratesTable = viewChild<MatTable<unknown>>('ratesTable');

  ngOnInit(): void {
    this.initTableFormCols();
    this.updateDisplayedColumns();
    this.emitRateTableUpdates();
  }

  get columnsFormArray() {
    return this.ratesForm.get('columns') as FormArray<
      FormGroup<{
        comparison: FormControl<string>;
        value: FormControl<number>;
      }>
    >;
  }

  get ratesFormArray() {
    return this.ratesForm.get('rates') as FormArray<
      FormGroup<{
        rate: FormControl<number>;
      }>
    >;
  }

  get hasOnlyZeroWeight(): boolean {
    return this.columnsFormArray.length === 1;
  }

  get weightUnitBasedOnFreightType() {
    if (this.isAirFreight()) return WeightUnits.KILOGRAM;
    return WeightUnits.CBM;
  }

  get measurementTypeLabel() {
    if (this.isAirFreight()) return 'Weight';
    return 'Volume';
  }

  addColumn() {
    const columnsArray = this.ratesForm.get('columns') as FormArray<
      FormGroup<{
        comparison: FormControl<string>;
        value: FormControl<number>;
      }>
    >;

    columnsArray.push(
      this.fb.group({
        comparison: new FormControl<string>(GREATER_THAN_COMPARISON_OPERATOR, Validators.required),
        value: new FormControl<number | null>(0, Validators.required),
      })
    );

    const ratesArray = this.ratesForm.get('rates') as FormArray<
      FormGroup<{
        rate: FormControl<number>;
      }>
    >;

    ratesArray.push(
      this.fb.group({
        rate: [0],
      })
    );
    this.updateDisplayedColumns();
  }

  removeColumn(index: number) {
    const columnsArray = this.ratesForm.get('columns') as FormArray;
    columnsArray.removeAt(index);

    const ratesWeightsArray = this.ratesForm.get('rates') as FormArray;
    ratesWeightsArray.removeAt(index);

    // If air freight and only one column remains, set it to ">0"
    if (this.isAirFreight() && columnsArray.length === 1) {
      const lastColumn = columnsArray.at(0) as FormGroup;
      lastColumn.get('comparison')?.setValue(GREATER_THAN_COMPARISON_OPERATOR);
      lastColumn.get('value')?.setValue(0);
    }

    this.updateDisplayedColumns();
  }

  private emitRateTableUpdates() {
    this.ratesForm.valueChanges
      .pipe(
        takeUntil(this.destroy),
        tap(table => {
          const { columns, rates } = table;
          const ratesValues = rates.map(rate => rate.rate);
          const weightValues = columns.map(({ comparison, value }) => {
            if (comparison === LESS_THAN_COMPARISON_OPERATOR && value !== 0) return -value;
            return value;
          });

          this.tableDataUpdateEvent.emit({
            rates: ratesValues,
            weights: weightValues,
            weightUnit: this.weightUnitBasedOnFreightType,
          });
        })
      )
      .subscribe();
  }

  private updateDisplayedColumns() {
    this.displayedWeightColumns = ['volumeWeight'];
    const columnsArray = this.ratesForm.get('columns') as FormArray;
    for (let i = 0; i < columnsArray.length; i++) {
      this.displayedWeightColumns.push('weight_' + i);
    }
  }

  private initTableFormCols() {
    const columnsArray = this.ratesForm.get('columns') as FormArray<
      FormGroup<{
        comparison: FormControl<string>;
        value: FormControl<number>;
      }>
    >;
    const ratesArray = this.ratesForm.get('rates') as FormArray<
      FormGroup<{
        rate: FormControl<number>;
      }>
    >;

    if (!this.disableDefaultColumns()) {
      this.weightColumns().forEach(column => {
        columnsArray.push(
          this.fb.group({
            comparison: [column.comparison],
            value: [column.value],
          })
        );
      });

      this.rateColumns().forEach(column => {
        ratesArray.push(this.fb.group({ rate: [column.rate] }));
      });
    }
  }

  private generateWeightColDataBasedOnProvidedInputs(): WeightCell[] {
    const noInitialWeights = this.inputWeights().length === 0;

    if (noInitialWeights) {
      const initialDefaultWeight = 0;
      return this.getWeightColsDataArray([initialDefaultWeight]);
    }

    return this.getWeightColsDataArray(this.inputWeights());
  }

  private generateRateColDataBasedOnProvidedInputs(): RateCell[] {
    const onlyWeightsProvided = this.inputWeights().length > 0 && this.inputRates().length === 0;

    if (onlyWeightsProvided) {
      const defaultRatesForProvidedWeights = Array(this.inputWeights().length).fill(0);
      return this.getRateColsDataArray(defaultRatesForProvidedWeights);
    }

    const noRatesProvided = this.inputRates().length === 0;
    if (noRatesProvided) {
      const initialDefaultRate = 0;
      return this.getRateColsDataArray([initialDefaultRate]);
    }

    return this.getRateColsDataArray(this.inputRates());
  }

  private getWeightColsDataArray(weights: number[]): WeightCell[] {
    return weights.map(weight => {
      return {
        comparison: weight >= 0 ? GREATER_THAN_COMPARISON_OPERATOR : LESS_THAN_COMPARISON_OPERATOR,
        value: Math.abs(weight),
      };
    });
  }

  private getRateColsDataArray(rates: number[]): RateCell[] {
    return rates.map(rate => {
      return {
        rate,
      };
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
