import { Country } from '@shared/models/country.model';
import { gql } from 'apollo-angular';

export const GET_COUNTRY_BY_ID_QUERY = gql`
  query GetCountryById($countryId: String!) {
    getCountryById(countryId: $countryId) {
      _id
      countryCode
      countryName
      createdBy
      currencies {
        _id
        code
        createdBy
        currency
        shortCode
        updatedBy
      }
      gmtOffset
      states
      timezone
      updatedBy
    }
  }
`;

export type GetCountryByIdDTO = {
  getCountryById: Country;
};
