import { RequirePasswordReset } from '@core/models/require-password-reset.model';
import { TokenObject } from '@core/models/token-object.model';

export function isTokenObject(value: TokenObject | RequirePasswordReset): value is TokenObject {
  return 'accessToken' in value;
}

export function isRequirePasswordReset(value: TokenObject | RequirePasswordReset): value is RequirePasswordReset {
  return 'requirePasswordReset' in value;
}
