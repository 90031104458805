import { LoadType } from '@shared/enums/load-type.enum';
import { DrayageCharges } from 'app/modules/contract-management/models/long-term-contract/drayage-charges.model';
import { LocationPair } from 'app/modules/contract-management/models/long-term-contract/location-pair.model';
import { Currency } from 'app/modules/organizations-manager/models/currency.model';
import { AdditionalChargeV2 } from 'app/modules/procurement/models/additional-charge-v2.model';
import { BiddingConfigV2 } from '../../models/bidding-config.model';
import { LongTermTenderBidDrawerMetadata } from '../../models/long-term-bidding-drawer-data.model';
import { LongTermTenderBidModel } from '../../models/long-term-tender-bid.model';

const CREATE_LONG_TERM_TENDER_BID_PREFIX = '[Create Long Term Tender Bid]';

export class CreateLongTermTenderBid {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Create Long Term Tender Bid`;
}

export class SetBidConfig {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Set Bid Config`;
  constructor(public config: BiddingConfigV2) {}
}

export class SetTenderMetaData {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Set Tender Meta Data`;
  constructor(public tenderMetaData: Pick<LongTermTenderBidDrawerMetadata, 'tenderMetaData'>) {}
}

export class CreateNewBid {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Create New Bid`;
  constructor(public bid: LongTermTenderBidModel) {}
}

export class DeleteBid {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Delete Bid`;
  constructor(public bid: LongTermTenderBidModel) {}
}

export class SetCurrentViewingBid {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Set Current Viewing Bid`;
  constructor(public bidId: string) {}
}

export class ResetLongTermTenderBidState {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Reset Long Term Tender Bid State`;
}

export class AddPortPair {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Add Port Pair`;
  constructor(public portPair: LocationPair) {}
}

export class SetSelectedPortPairs {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Set Selected Port Pairs`;
  constructor(
    public bidId: string,
    public locationPairs: LocationPair[]
  ) {}
}

export class DeletePortPair {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Delete Port Pair`;
  constructor(
    public bidId: string,
    public locationPairId: string
  ) {}
}

export class UpdateBidAdditionalCharges {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Update Bid Additional Charges`;
  constructor(
    public bidId: string,
    public locationPairsId: string,
    public additionalCharges: AdditionalChargeV2[]
  ) {}
}

export class SetBidCurrency {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Set Bid Currency`;
  constructor(
    public bidId: string,
    public currency: Currency
  ) {}
}

export class DeleteVesselScheduleRow {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Delete Vessel Schedule Row`;
  constructor(
    public bidId: string,
    public locationPairId: string,
    public vesselScheduleId: string,
    public loadType: LoadType
  ) {}
}

export class DeleteFlightScheduleRow {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Delete Flight Schedule Row`;
  constructor(
    public bidId: string,
    public locationPairId: string,
    public flightScheduleId: string
  ) {}
}

export class UpdateBidDrayageCharges {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Update Bid Drayage Charges`;
  constructor(
    public bidId: string,
    public locationPairId: string,
    public drayageCharges: DrayageCharges
  ) {}
}

export class UpdateBidRemarks {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Update Bid Remarks`;
  constructor(
    public bidId: string,
    public locationPairId: string,
    public remarks: string
  ) {}
}

export class SetBidRemarksEnabled {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Set Bid Remarks Enabled`;
  constructor(
    public bidId: string,
    public locationPairId: string,
    public enabled: boolean
  ) {}
}

export class SetBidRemarksValid {
  static readonly type = `${CREATE_LONG_TERM_TENDER_BID_PREFIX} Set Bid Remarks Valid`;
  constructor(
    public bidId: string,
    public locationPairId: string,
    public valid: boolean
  ) {}
}
