import { Component, inject, Input, input, OnDestroy, OnInit, signal } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  SetBidRemarksEnabled,
  SetBidRemarksValid,
  UpdateBidRemarks,
} from 'app/modules/bidding-wizard/store/long-term-tender-bid/bid.actions';
import { LongTermTenderBidState } from 'app/modules/bidding-wizard/store/long-term-tender-bid/bid.state';
import { characterLimitValidatorForRichTextEditor } from 'app/utils/rich-text-editor-validators.util';
import { debounceTime, distinctUntilChanged, pairwise, startWith, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'hmt-long-term-bid-port-pair-remarks',
  templateUrl: './long-term-bid-port-pair-remarks.component.html',
  styleUrls: ['./long-term-bid-port-pair-remarks.component.scss'],
})
export class LongTermBidPortPairRemarksComponent implements OnInit, OnDestroy {
  formBuilder = inject(FormBuilder);
  store = inject(Store);
  destroy$ = new Subject<void>();

  locationPairId = input.required<string>();

  @Input() viewOnlyMode = false;

  $currentViewingBid = this.store.select(LongTermTenderBidState.getCurrentViewingBid);

  longTermBidPortPairRemarksForm = this.formBuilder.group({
    remarks: this.formBuilder.control('', [characterLimitValidatorForRichTextEditor(1000)]),
  });

  isRemarksEnabled = signal<boolean>(false);

  ngOnInit(): void {
    if (this.viewOnlyMode) {
      this.longTermBidPortPairRemarksForm.controls.remarks.disable();
    }

    this.$currentViewingBid
      .pipe(startWith(null), pairwise(), takeUntil(this.destroy$))
      .subscribe(([prevBid, currentBid]) => {
        if ((prevBid !== null && prevBid.bid.bidId !== currentBid.bid.bidId) || prevBid === null) {
          const services = currentBid.bid.serviceMetaData;
          const exactService = services.find(s => s.locationPairs.some(pair => pair._id === this.locationPairId()));
          if (exactService) {
            const exactLocationPair = exactService.locationPairs.find(pair => pair._id === this.locationPairId());
            this.isRemarksEnabled.set(exactLocationPair.bidRemarksEnabled);
            this.longTermBidPortPairRemarksForm.get('remarks').setValue(exactLocationPair.bidRemarks);
          }
        }
      });

    this.listenToRemarksChanges();
  }

  listenToRemarksChanges() {
    this.longTermBidPortPairRemarksForm
      .get('remarks')
      .valueChanges.pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(remarks => {
        if (this.isRemarksEnabled()) {
          const currentBid = this.store.selectSnapshot(LongTermTenderBidState.getCurrentViewingBid);
          this.store.dispatch(new UpdateBidRemarks(currentBid.bid.bidId, this.locationPairId(), remarks));
        }
      });

    this.longTermBidPortPairRemarksForm
      .get('remarks')
      .statusChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(status => {
        const isRemarksValid = status === 'VALID';
        const currentBid = this.store.selectSnapshot(LongTermTenderBidState.getCurrentViewingBid);
        this.store.dispatch(new SetBidRemarksValid(currentBid.bid.bidId, this.locationPairId(), isRemarksValid));
      });
  }

  enableRemarks() {
    this.isRemarksEnabled.set(true);
    const currentBid = this.store.selectSnapshot(LongTermTenderBidState.getCurrentViewingBid);

    this.store.dispatch(new SetBidRemarksEnabled(currentBid.bid.bidId, this.locationPairId(), true));
  }

  disableRemarks() {
    this.isRemarksEnabled.set(false);
    const currentBid = this.store.selectSnapshot(LongTermTenderBidState.getCurrentViewingBid);

    this.store.dispatch(new SetBidRemarksEnabled(currentBid.bid.bidId, this.locationPairId(), false));
    this.store.dispatch(new SetBidRemarksValid(currentBid.bid.bidId, this.locationPairId(), true));
    this.longTermBidPortPairRemarksForm.get('remarks').setValue(null);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
