import { BiddingConfigV2 } from './bidding-config.model';
import { LongTermTenderBidDrawerMetadata } from './long-term-bidding-drawer-data.model';
import { LongTermTenderBidModel } from './long-term-tender-bid.model';

export interface LongTermTenderBidStateModel {
  config: BiddingConfigV2;
  bids: LongTermTenderBidModel[];
  curViewingBid: LongTermTenderBidModel;
  tenderMetaData: Pick<LongTermTenderBidDrawerMetadata, 'tenderMetaData'>;
}

export const CREATE_LONG_TERM_TENDER_BID_DEFAULT_STATE: LongTermTenderBidStateModel = {
  bids: [],
  curViewingBid: null,
  config: {
    title: '',
    favorite: false,
    delete: false,
    withdraw: false,
    create: false,
    editableInputs: false,
    footer: false,
  },
  tenderMetaData: null,
};
