import { Component } from '@angular/core';
import { EnhancedDatagridDateRendererParams } from '@shared/types/enhanced-grid-types/enhanced-grid-components.type';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'hmt-enhanced-datagrid-date-renderer',
  templateUrl: './enhanced-datagrid-date-renderer.component.html',
  styleUrls: ['./enhanced-datagrid-date-renderer.component.scss'],
})
export class EnhancedDatagridDateRendererComponent<TData = Record<string, unknown>>
  implements ICellRendererAngularComp
{
  private params!: EnhancedDatagridDateRendererParams<TData>;
  value!: Date;

  agInit(params: EnhancedDatagridDateRendererParams<TData>): void {
    this.params = params;
    this.setValue(params);
  }

  refresh(params: EnhancedDatagridDateRendererParams<TData>): boolean {
    this.params = params;
    this.setValue(params);
    return true;
  }

  private setValue(params: EnhancedDatagridDateRendererParams<TData>) {
    this.value = params.value;
  }
}
