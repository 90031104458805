export function htmlToPureText(html: string) {
  if (!html) {
    return '';
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.textContent;
}

export function isHtmlActuallyEmpty(html: string) {
  const pureText = htmlToPureText(html);
  return pureText.trim() === '';
}
