import { gql } from 'apollo-angular';

export const GET_ORG_DETAILS_SUMMARY_BY_ID_QUERY = gql`
  query GetOrgDetailsSummaryById($orgId: String!) {
    getOrganizationById(orgId: $orgId) {
      _id
      orgName
      shortName
      tickerCode
      logo
    }
  }
`;

export type GetOrgDetailsSummaryByIdDResponse = {
  getOrganizationById: {
    _id: string;
    orgName: string;
    shortName: string;
    tickerCode: string;
    logo: string;
  };
};
