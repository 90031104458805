import { gql } from 'apollo-angular';

export const CHECK_ORG_SHORTNAME_AVAILABILITY_QUERY = gql`
  query CheckOrgShortNameAvailabilityQuery($shortName: String!) {
    checkIfOrgShortNameExists(shortName: $shortName)
  }
`;

export type CheckOrgShortNameAvailabilityDTO = {
  checkIfOrgShortNameExists: boolean;
};
