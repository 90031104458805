import { Injectable } from '@angular/core';
import { LocationPair } from 'app/modules/contract-management/models/long-term-contract/location-pair.model';
import { LongTermTenderBidModel } from '../models/long-term-tender-bid.model';

@Injectable({
  providedIn: 'root',
})
export class LongTermBidValidatorService {
  validateBid(bid: LongTermTenderBidModel): LongTermTenderBidModel {
    let isBidValid = true;

    bid.serviceMetaData.forEach(service => {
      if (service.locationPairs.length === 0 || !service.locationPairs) {
        isBidValid = false;
        return;
      }

      isBidValid = service.locationPairs.every(pair => this.isPortPairValid(pair));
    });

    return { ...bid, isValid: isBidValid };
  }

  // TODO: Add other validations in the future
  private isPortPairValid(pair: LocationPair) {
    let isPortPairValid = true;
    isPortPairValid = this.isDrayageValid(pair);

    return isPortPairValid;
  }

  private isDrayageValid(locationPair: LocationPair) {
    if (!locationPair.hasDrayage) {
      return true;
    }

    const hasFclRequirement = locationPair.shipmentMethodsData.FCL?.containerTypes.length > 0;
    const hasLclRequirement = locationPair.shipmentMethodsData.LCL?.volumeWeights.length > 0;

    if (hasFclRequirement && hasLclRequirement) {
      return this.isDrayageValidForFcl(locationPair) && this.isDrayageValidForLcl(locationPair);
    }

    if (hasFclRequirement) {
      return this.isDrayageValidForFcl(locationPair);
    }

    if (hasLclRequirement) {
      return this.isDrayageValidForLcl(locationPair);
    }

    return true;
  }

  private isDrayageValidForFcl(locationPair: LocationPair) {
    if (!locationPair?.drayageCharges) return false;

    try {
      const { loadRates } = locationPair.drayageCharges;
      return loadRates.every(rate => rate.amount > 0);
    } catch (_) {
      return false;
    }
  }

  private isDrayageValidForLcl(locationPair: LocationPair) {
    if (!locationPair?.drayageCharges) return false;

    try {
      const { volumeWeights, rates } = locationPair.drayageCharges;

      const isForAnyCBM = volumeWeights?.values.length === 1;
      if (isForAnyCBM) {
        return rates?.values.every(rate => rate > 0);
      }

      const isVolumeWeightsValid = volumeWeights?.values.every(
        value => value !== null && value !== undefined && typeof value === 'number'
      ); // OKG is valid now if it's not null or undefined and is a number
      const isRatesValid = rates?.values.every(rate => rate > 0);

      return isVolumeWeightsValid && isRatesValid;
    } catch (e) {
      return false;
    }
  }
}
