import { gql } from 'apollo-angular';
import { OrgDocuments } from '../models/org-documents.model';

export const REMOVE_DOCUMENT_FROM_ORG_DOCUMENT_CATEGORY_QUERY = gql`
  mutation RemoveDocumentFromOrgDocumentCategory(
    $removeDocumentFromOrgDocumentCategoryInput: RemoveDocumentFromOrgDocumentCategoryDto!
  ) {
    removeDocumentFromOrgDocumentCategory(
      removeDocumentFromOrgDocumentCategoryInput: $removeDocumentFromOrgDocumentCategoryInput
    ) {
      _id
      createdAt
      createdBy
      updatedAt
      orgId
      updatedBy
      categories {
        _id
        createdAt
        createdBy
        deleted
        name
        updatedAt
        updatedBy
        documents {
          customFileName
          fileId
          fileName
          fileType
          isActive
          isUploaded
          orgUserId
          uploadedDate
          uploadedByName
        }
      }
    }
  }
`;

export interface RemoveDocumentFromOrgDocumentCategoryDto {
  removeDocumentFromOrgDocumentCategory: OrgDocuments;
}

export interface RemoveDocumentFromOrgDocumentCategoryInputDto {
  removeDocumentFromOrgDocumentCategoryInput: {
    categoryId: string;
    fileId: string;
    orgId: string;
  };
}
