import { PaginatedResponse } from '@shared/models/paginated-response.model';
import { gql } from 'apollo-angular';
import { FlightSchedule } from '../models/flight-schedule.model';

export const GET_FLIGHT_SCHEDULES_BY_ORG_ID = gql`
  query GetFlightSchedulesByOrgId($getFlightSchedulesByOrgIdInput: GetFlightSchedulesByOrgIdInput!) {
    getFlightSchedulesByOrgId(getFlightSchedulesByOrgIdInput: $getFlightSchedulesByOrgIdInput) {
      items {
        _id
        airLine
        createdAt
        createdBy
        deleted
        firstLegDepartureDays
        firstLegFrequency
        firstLegNoOfDays
        flightCutoff
        flightName
        from
        fromEta
        fromEtd
        orgId
        routingDetails {
          type
          stops
        }
        secondLegArrivalDays
        secondLegFrequency
        secondLegNoOfDays
        to
        toEta
        toEtd
        totalTransitTime
        updatedAt
        updatedBy
        versionHistory {
          _id
          changedField
          createdAt
          createdBy
          newValue
          oldValue
          orgUserId
          updatedAt
          updatedBy
        }
        rateStructure {
          _id
          contractedParty {
            _id
          }
          contractedPartyId
          createdAt
          createdBy
          operationId
          orgId
          rateCardType
          template
          updatedAt
          updatedBy
          rateCardData {
            _id
            additionalCharges {
              amount
              currencyCode
              description
            }
            clauses {
              clauseType
              name
              enabled
            }
            createdAt
            createdBy
            currencyCode
            name
            operationId
            orgId
            serviceType
            templateType
            updatedAt
            updatedBy
            data
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageSize
        total
      }
    }
  }
`;

export interface GetFlightSchedulesByOrgIdInput {
  limit: number;
  offset: number;
  orgId: string;
  airLine?: string;
  flightName?: string;
  from?: string;
  startDate?: string;
  to?: string;
  currencyCode?: string;
}

// TODO: The above interface should have startDate and endDate as Date
export interface GetFlightSchedulesByOrgIDForBidInput extends Omit<GetFlightSchedulesByOrgIdInput, 'startDate'> {
  startDate: Date;
  endDate: Date;
}

export type GetFlightSchedulesByOrgIdInputBase = Omit<GetFlightSchedulesByOrgIdInput, 'offset' | 'limit' | 'orgId'>;

export interface GetFlightSchedulesByOrgIdQueryResponse {
  getFlightSchedulesByOrgId: PaginatedResponse<FlightSchedule>;
}
