// These are the default rates for LCL in the future we will get this from the backend based on the shipping line

export const DEFAULT_OCEAN_FREIGHT_LCL_VOLUME_WEIGHTS = [0];
export const DEFAULT_AIR_FREIGHT_LCL_VOLUME_WEIGHTS = [-45, 45, 100, 300, 500];

export const DEFAULT_OCEAN_FREIGHT_LCL_VOLUME_RATES = [1];
export const DEFAULT_AIR_FREIGHT_LCL_VOLUME_RATES = [3.05, 2.45, 2.15, 2.05, 2.01];

export const GREATER_THAN_COMPARISON_OPERATOR = '>';
export const LESS_THAN_COMPARISON_OPERATOR = '<';
