import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ServiceTypes } from 'app/modules/wizard/enums/service-types.enum';
import { forkJoin, tap } from 'rxjs';
import {
  CREATE_LONG_TERM_TENDER_BID_DEFAULT_STATE,
  LongTermTenderBidStateModel,
} from '../../models/create-bid-state.model';
import { LongTermServiceMetaData, LongTermTenderBidModel } from '../../models/long-term-tender-bid.model';
import { BidScheduleService } from '../../services/bid-vessel-schedule.service';
import { LongTermBidValidatorService } from '../../services/long-term-bid-validator.service';
import {
  CreateNewBid,
  DeleteBid,
  DeleteFlightScheduleRow,
  DeletePortPair,
  DeleteVesselScheduleRow,
  ResetLongTermTenderBidState,
  SetBidConfig,
  SetBidCurrency,
  SetBidRemarksEnabled,
  SetBidRemarksValid,
  SetCurrentViewingBid,
  SetSelectedPortPairs,
  SetTenderMetaData,
  UpdateBidAdditionalCharges,
  UpdateBidDrayageCharges,
  UpdateBidRemarks,
} from './bid.actions';

@State<LongTermTenderBidStateModel>({
  name: 'longTermTenderBid',
  defaults: CREATE_LONG_TERM_TENDER_BID_DEFAULT_STATE,
})
@Injectable()
export class LongTermTenderBidState {
  private readonly bidScheduleService = inject(BidScheduleService);
  private readonly longTermBidValidatorService = inject(LongTermBidValidatorService);

  @Selector()
  static getConfig(state: LongTermTenderBidStateModel) {
    return state.config;
  }

  @Selector()
  static getCurrentViewingBid(
    state: LongTermTenderBidStateModel
  ): { bid: LongTermTenderBidModel; name: string } | null {
    if (!state.curViewingBid) return null;

    const bidIndex = state.bids.findIndex(bid => bid.bidId === state.curViewingBid?.bidId);

    return {
      bid: state.curViewingBid,
      name: `Bid ${bidIndex + 1}`,
    };
  }

  @Selector()
  static getAllBids(state: LongTermTenderBidStateModel) {
    return state.bids;
  }

  @Selector()
  static getTotalBidCount(state: LongTermTenderBidStateModel) {
    return state.bids.length;
  }

  @Selector()
  static getBidsForSidePanel(state: LongTermTenderBidStateModel): { id: string; name: string }[] {
    return state.bids.map((bid, index) => ({
      id: bid.bidId,
      name: `Bid ${index + 1}`,
    }));
  }

  @Selector()
  static getTenderMetaData(state: LongTermTenderBidStateModel) {
    return state.tenderMetaData.tenderMetaData;
  }

  @Selector()
  static getLocationPairType(state: LongTermTenderBidStateModel) {
    const serviceType = state.tenderMetaData.tenderMetaData.requirementMetaData.serviceMetaData[0].serviceId;
    return serviceType === ServiceTypes.OCEAN_FREIGHT ? 'Port' : 'Airport';
  }

  @Selector()
  static getBidValidity(state: LongTermTenderBidStateModel) {
    return state.bids.every(
      bid =>
        bid.isValid &&
        bid.serviceMetaData.every(service =>
          service.locationPairs.every(pair => pair.bidRemarksValid || pair.bidRemarksValid === undefined)
        ) // INFO: Making sure all the remarks are valid as well
    );
  }

  @Action(SetBidConfig)
  setBidConfig({ patchState }: StateContext<LongTermTenderBidStateModel>, { config }: SetBidConfig) {
    patchState({
      config,
    });
  }

  @Action(CreateNewBid)
  createNewBid({ patchState, getState }: StateContext<LongTermTenderBidStateModel>, { bid }: CreateNewBid) {
    patchState({
      bids: [...getState().bids, bid],
      curViewingBid: bid,
    });
  }

  @Action(DeleteBid)
  deleteBid({ patchState, getState }: StateContext<LongTermTenderBidStateModel>, { bid }: DeleteBid) {
    const state = getState();

    const deletedBidIndex = state.bids.findIndex(b => b.bidId === bid.bidId);
    const newUpdatedBids = state.bids.filter(b => b.bidId !== bid.bidId);
    const newCurrentViewingBid = newUpdatedBids.length === 0 ? null : newUpdatedBids[deletedBidIndex - 1];

    patchState({
      bids: newUpdatedBids,
      curViewingBid: newCurrentViewingBid,
    });
  }

  @Action(SetCurrentViewingBid)
  setCurrentViewingBid(
    { patchState, getState }: StateContext<LongTermTenderBidStateModel>,
    { bidId }: SetCurrentViewingBid
  ) {
    const state = getState();
    const currentBid = state.curViewingBid;

    if (currentBid) {
      const updatedBids = state.bids.map(bid => (bid.bidId === currentBid.bidId ? currentBid : bid));

      const newCurrentViewingBid = updatedBids.find(b => b.bidId === bidId);

      patchState({
        bids: updatedBids,
        curViewingBid: newCurrentViewingBid,
      });
    } else {
      const newCurrentViewingBid = state.bids.find(b => b.bidId === bidId);
      patchState({
        curViewingBid: newCurrentViewingBid,
      });
    }
  }

  @Action(SetTenderMetaData)
  setTenderMetaData({ patchState }: StateContext<LongTermTenderBidStateModel>, { tenderMetaData }: SetTenderMetaData) {
    patchState({
      tenderMetaData,
    });
  }

  @Action(SetBidCurrency)
  setBidCurrency(
    { getState, patchState }: StateContext<LongTermTenderBidStateModel>,
    { bidId, currency }: SetBidCurrency
  ) {
    const state = getState();
    patchState({
      curViewingBid: {
        ...state.curViewingBid,
        currency: currency.code,
      },
      bids: state.bids.map(bid => (bid.bidId === bidId ? { ...bid, currency: currency.code } : bid)),
    });
  }

  @Action(SetSelectedPortPairs)
  setSelectedPortPairs(
    { getState, patchState }: StateContext<LongTermTenderBidStateModel>,
    { bidId, locationPairs }: SetSelectedPortPairs
  ) {
    const state = getState();
    const bids = state.bids;
    const bidIndex = bids.findIndex(b => b.bidId === bidId);

    if (bidIndex === -1) {
      return undefined;
    }

    const bid = bids[bidIndex];
    const serviceId = state.tenderMetaData.tenderMetaData.requirementMetaData.serviceMetaData[0].serviceId;

    const existingLocationPairs = bid.serviceMetaData[0]?.locationPairs || [];

    const locationPairObservables = locationPairs.map(locationPair =>
      this.bidScheduleService.setSchedulesForLocationPair({
        locationPair,
        serviceId: serviceId as ServiceTypes,
        currencyCode: state.tenderMetaData.tenderMetaData.requirementMetaData.currency,
        startDate: state.tenderMetaData.tenderMetaData.requirementMetaData.rateValidityPeriod.effectiveDate,
        endDate: state.tenderMetaData.tenderMetaData.requirementMetaData.rateValidityPeriod.to,
      })
    );

    return forkJoin(locationPairObservables).pipe(
      tap(transformedLocationPairs => {
        const mergedLocationPairs = transformedLocationPairs.reduce(
          (acc, newPair) => {
            const existingPairIndex = acc.findIndex(pair => pair._id === newPair._id);
            if (existingPairIndex !== -1) {
              acc[existingPairIndex] = newPair;
            } else {
              acc.push(newPair);
            }
            return acc;
          },
          [...existingLocationPairs]
        );

        const updatedServiceMetaData: LongTermServiceMetaData = {
          serviceId,
          locationPairs: mergedLocationPairs,
        };

        const updatedBid: LongTermTenderBidModel = {
          ...bid,
          serviceMetaData: [updatedServiceMetaData],
        };

        const updatedBids = [...bids];
        updatedBids[bidIndex] = updatedBid;

        const validatedBids = updatedBids.map(bid => this.longTermBidValidatorService.validateBid(bid));
        const validatedBid = this.longTermBidValidatorService.validateBid(updatedBid);

        patchState({
          bids: validatedBids,
          curViewingBid: validatedBid,
        });
      })
    );
  }

  @Action(DeleteVesselScheduleRow)
  deleteVesselScheduleRow(
    { getState, patchState }: StateContext<LongTermTenderBidStateModel>,
    { bidId, locationPairId, vesselScheduleId, loadType }: DeleteVesselScheduleRow
  ) {
    const state = getState();
    const bids = state.bids;
    const bidIndex = bids.findIndex(b => b.bidId === bidId);

    const bid = bids[bidIndex];
    const updatedBid = {
      ...bid,
      serviceMetaData: bid.serviceMetaData.map(service => ({
        ...service,
        locationPairs: service.locationPairs.map(pair =>
          pair._id === locationPairId
            ? {
                ...pair,
                vesselSchedules: {
                  ...pair.vesselSchedules,
                  [loadType]: {
                    ...pair.vesselSchedules[loadType],
                    schedule: pair.vesselSchedules[loadType].schedule.filter(
                      schedule => schedule._id !== vesselScheduleId
                    ),
                  },
                },
              }
            : pair
        ),
      })),
    };

    const updatedBids = [...bids];
    updatedBids[bidIndex] = updatedBid;

    patchState({
      bids: updatedBids,
      curViewingBid: updatedBid,
    });
  }

  @Action(DeleteFlightScheduleRow)
  deleteFlightScheduleRow(
    { getState, patchState }: StateContext<LongTermTenderBidStateModel>,
    { bidId, locationPairId, flightScheduleId }: DeleteFlightScheduleRow
  ) {
    const state = getState();
    const bids = state.bids;
    const bidIndex = bids.findIndex(b => b.bidId === bidId);

    const bid = bids[bidIndex];
    const updatedBid = {
      ...bid,
      serviceMetaData: bid.serviceMetaData.map(service => ({
        ...service,
        locationPairs: service.locationPairs.map(pair =>
          pair._id === locationPairId
            ? {
                ...pair,
                flightSchedules: {
                  ...pair.flightSchedules,
                  schedule: pair.flightSchedules.schedule.filter(schedule => schedule._id !== flightScheduleId),
                },
              }
            : pair
        ),
      })),
    };

    const updatedBids = [...bids];
    updatedBids[bidIndex] = updatedBid;

    patchState({
      bids: updatedBids,
      curViewingBid: updatedBid,
    });
  }

  @Action(DeletePortPair)
  deletePortPair(
    { getState, patchState }: StateContext<LongTermTenderBidStateModel>,
    { bidId, locationPairId }: DeletePortPair
  ) {
    const state = getState();
    const bids = state.bids;
    const bidIndex = bids.findIndex(b => b.bidId === bidId);

    const bid = bids[bidIndex];
    const updatedBid = {
      ...bid,
      serviceMetaData: bid.serviceMetaData.map(service => ({
        ...service,
        locationPairs: service.locationPairs.filter(pair => pair._id !== locationPairId),
      })),
    };

    const updatedBids = [...bids];
    updatedBids[bidIndex] = updatedBid;

    const validatedBids = updatedBids.map(bid => this.longTermBidValidatorService.validateBid(bid));
    const validityUpdatedBid = this.longTermBidValidatorService.validateBid(updatedBid);

    patchState({
      bids: validatedBids,
      curViewingBid: validityUpdatedBid,
    });
  }

  @Action(UpdateBidAdditionalCharges)
  updateBidAdditionalCharges(ctx: StateContext<LongTermTenderBidStateModel>, action: UpdateBidAdditionalCharges) {
    const state = ctx.getState();
    const currentBid = state.curViewingBid;

    const updatedBid = {
      ...currentBid,
      serviceMetaData: currentBid.serviceMetaData.map(service => ({
        ...service,
        locationPairs: service.locationPairs.map(pair =>
          pair._id === action.locationPairsId ? { ...pair, additionalCharges: action.additionalCharges } : pair
        ),
      })),
    };

    return ctx.patchState({
      curViewingBid: updatedBid,
      bids: state.bids.map(bid => (bid.bidId === currentBid.bidId ? updatedBid : bid)),
    });
  }

  @Action(UpdateBidDrayageCharges)
  updateBidDrayageCharges(ctx: StateContext<LongTermTenderBidStateModel>, action: UpdateBidDrayageCharges) {
    const state = ctx.getState();
    const currentBid = state.curViewingBid;

    const updatedBid = {
      ...currentBid,
      serviceMetaData: currentBid.serviceMetaData.map(service => ({
        ...service,
        locationPairs: service.locationPairs.map(pair =>
          pair._id === action.locationPairId ? { ...pair, drayageCharges: action.drayageCharges } : pair
        ),
      })),
    };

    const validityUpdatedBid = this.longTermBidValidatorService.validateBid(updatedBid);

    return ctx.patchState({
      curViewingBid: validityUpdatedBid,
      bids: state.bids.map(bid => (bid.bidId === currentBid.bidId ? validityUpdatedBid : bid)),
    });
  }

  @Action(ResetLongTermTenderBidState)
  resetLongTermTenderBidState({ setState }: StateContext<LongTermTenderBidStateModel>) {
    setState(CREATE_LONG_TERM_TENDER_BID_DEFAULT_STATE);
  }

  @Action(UpdateBidRemarks)
  updateBidRemarks(
    ctx: StateContext<LongTermTenderBidStateModel>,
    { bidId, locationPairId, remarks }: UpdateBidRemarks
  ) {
    const state = ctx.getState();
    const bids = state.bids;
    const bidIndex = bids.findIndex(b => b.bidId === bidId);

    const bid = bids[bidIndex];

    const updatedBid = {
      ...bid,
      serviceMetaData: bid.serviceMetaData.map(service => ({
        ...service,
        locationPairs: service.locationPairs.map(pair =>
          pair._id === locationPairId ? { ...pair, bidRemarks: remarks } : pair
        ),
      })),
    };

    const updatedBids = [...bids];
    updatedBids[bidIndex] = updatedBid;

    return ctx.patchState({
      bids: updatedBids,
      curViewingBid: updatedBid,
    });
  }

  @Action(SetBidRemarksEnabled)
  setBidRemarksEnabled(
    ctx: StateContext<LongTermTenderBidStateModel>,
    { bidId, enabled, locationPairId }: SetBidRemarksEnabled
  ) {
    const state = ctx.getState();
    const bids = state.bids;
    const bidIndex = bids.findIndex(b => b.bidId === bidId);

    const bid = bids[bidIndex];

    const updatedBid = {
      ...bid,
      serviceMetaData: bid.serviceMetaData.map(service => ({
        ...service,
        locationPairs: service.locationPairs.map(pair => {
          if (pair._id === locationPairId) {
            if (!enabled) {
              // When disabled, remove bidRemarks if it exists
              const { bidRemarks, ...pairWithoutRemarks } = pair;
              return { ...pairWithoutRemarks, bidRemarksEnabled: enabled, bidRemarksValid: true };
            }
            return { ...pair, bidRemarksEnabled: enabled };
          }
          return pair;
        }),
      })),
    };

    const updatedBids = [...bids];
    updatedBids[bidIndex] = updatedBid;

    return ctx.patchState({
      bids: updatedBids,
      curViewingBid: updatedBid,
    });
  }

  @Action(SetBidRemarksValid)
  setBidRemarksValid(
    ctx: StateContext<LongTermTenderBidStateModel>,
    { bidId, locationPairId, valid }: SetBidRemarksValid
  ) {
    const state = ctx.getState();
    const bids = state.bids;
    const bidIndex = bids.findIndex(b => b.bidId === bidId);

    const bid = bids[bidIndex];

    const updatedBid = {
      ...bid,
      serviceMetaData: bid.serviceMetaData.map(service => ({
        ...service,
        locationPairs: service.locationPairs.map(pair => {
          if (pair._id === locationPairId) {
            return { ...pair, bidRemarksValid: valid };
          }
          return pair;
        }),
      })),
    };

    const updatedBids = [...bids];
    updatedBids[bidIndex] = updatedBid;

    return ctx.patchState({
      bids: updatedBids,
      curViewingBid: updatedBid,
    });
  }
}
