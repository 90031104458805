import { HolidaysCalendar } from '@shared/models/holidays-calendar.model';
import { gql } from 'apollo-angular';

export const GET_HOLIDAYS_BY_COUNTRY_AND_YEAR_QUERY = gql`
  query GetHolidaysByCountryAndYear($countryCode: String!, $year: Int!) {
    getHolidaysByCountryAndYear(countryCode: $countryCode, year: $year) {
      _id
      country
      countryCode
      createdBy
      holidays {
        _id
        commonName
        createdBy
        date
        holidayType
        name
        operatingHours {
          _id
          createdBy
          from {
            _id
            createdBy
            hour
            minute
            updatedBy
          }
          to {
            _id
            createdBy
            hour
            minute
            updatedBy
          }
          updatedBy
        }
        updatedBy
      }
    }
  }
`;

export type GetHolidaysByCountryAndYearDTO = {
  getHolidaysByCountryAndYear: HolidaysCalendar | null;
};
