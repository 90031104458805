<div class="bid-remarks">
  @if (!isRemarksEnabled() && !viewOnlyMode) {
    <button mat-stroked-button (click)="enableRemarks()">
      <mat-icon>add</mat-icon>
      Add Remarks
    </button>
  }
  @if (isRemarksEnabled() || viewOnlyMode) {
    <div class="remarks-editor-container">
      <hmt-rich-text-editor
        [boldLabel]="true"
        [control]="longTermBidPortPairRemarksForm.controls.remarks"
        [formGroup]="longTermBidPortPairRemarksForm"
        [label]="'Your Remarks'"
        output-as="html"
        [characterLimit]="1000"
        placeholder="Add any remarks here ..."></hmt-rich-text-editor>

      @if (!viewOnlyMode) {
        <button mat-icon-button (click)="disableRemarks()" color="warn" class="close-button">
          <mat-icon>delete</mat-icon>
        </button>
      }
    </div>
  }
</div>
