<div class="you-are-here" *ngIf="youAreHere">
  <div class="status-bar-container">
    @for (status of bar; track status; let i = $index) {
      <div class="status-bar">
        <div class="child-circle-container">
          @for (sub of status.subStep; track sub) {
            <div
              class="child-circle"
              [ngStyle]="{ 'background-color': sub.enabled ? '#514ee5' : '#b5b5b5' }"
              (click)="sub.enabled ? navigateToUrl(sub.url) : null"
              (keydown)="sub.enabled && ($event.key === 'Enter' || $event.key === ' ') ? navigateToUrl(sub.url) : null"
              tabindex="0"
              [matTooltip]="sub?.tagName"></div>
          }
        </div>
        <div class="child-status-bar-container">
          @for (sub of status.subStep; track sub) {
            <div class="child-status-bar" [ngStyle]="{ 'background-color': sub.enabled ? '#514ee5' : '#b5b5b5' }"></div>
          }
          <div
            class="child-status-bar"
            [ngStyle]="{ 'background-color': steps[i + 1].enabled ? '#514ee5' : '#b5b5b5' }"></div>
        </div>
      </div>
    }
  </div>
  <div class="circle-container">
    @for (step of steps; track step) {
      <div class="parent-circle" [ngStyle]="{ 'background-color': step.enabled ? '#514ee5' : '#b5b5b5' }">
        <p class="parent-circle-title">{{ step.name }}</p>
      </div>
    }
  </div>
</div>
