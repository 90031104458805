import { gql } from 'apollo-angular';

export const CHECK_ORG_TICKER_CODE_AVAILABILITY_QUERY = gql`
  query CheckOrgTickerCodeAvailabilityQuery($tickerCode: String!) {
    checkIfTickerCodeExists(tickerCode: $tickerCode)
  }
`;

export type CheckOrgTickerCodeAvailabilityDTO = {
  checkIfTickerCodeExists: boolean;
};
