import { gql } from 'apollo-angular';
import { Organization } from '../models/organization.model';

// TODO: ADD OTHER NECESSARY FIELDS AS WELL
export const GET_PARTNER_ORGANIZATION_QUERY = gql`
  query GetSelfOrganization {
    getSelfOrganization {
      _id
      companyRegNum
      companyWebSite
      createdBy
      epfNumber
      etfNumber
      logo
      managedBy
      opsConfig {
        currencies {
          _id
          code
          createdBy
          currency
          shortCode
          updatedBy
        }
        defaultCurrency {
          _id
          code
          createdBy
          currency
          shortCode
          updatedBy
        }
        defaultLanguage {
          _id
          createdBy
          isoCode
          name
          updatedBy
          writingDirection
        }
        defaultPaymentMethod {
          paymentMethod {
            _id
            createdBy
            logo
            name
            updatedBy
          }
        }
        languages {
          _id
          createdBy
          isoCode
          name
          updatedBy
          writingDirection
        }
        paymentMethods {
          paymentMethod {
            _id
            createdBy
            logo
            name
            updatedBy
          }
        }
      }
      organizationFunctionalityType
      orgName
      orgRegisteredType
      orgSize
      orgType
      paidCustomer
      parentOrgId
      parentOrgName
      party
      principalContact {
        designation
        division
        email
        faxNumber
        firstName
        gender
        lastName
        mobile
        nic
        orgUserId
        officeContact {
          ext
          phoneNumber
        }
      }

      registeredAddress {
        addressLine
        city
        country
        state
        timeZone {
          label
          tzCode
          name
          utc
        }
        zipCode
      }

      shortName
      status
      tenant
      tickerCode
      tinNum
      updatedBy
      vatNum
      verticals
      phoneNumbers
      faxNumbers
    }
  }
`;

export interface GetPartnerOrganizationDTO {
  getSelfOrganization: Organization;
}
