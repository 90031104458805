import { inject, Injectable } from '@angular/core';
import { DEFAULT_OFFSET } from '@configs/constants';
import { NotificationService } from '@core/services/notification/notification.service';
import { AuthState } from '@core/store/auth/auth.state';
import { Store } from '@ngxs/store';
import { LoadType } from '@shared/enums/load-type.enum';
import { Apollo } from 'apollo-angular';
import { VesselSchedule } from 'app/modules/catalog-manager/models/vessel-schedule.model';
import { GetFlightSchedulesByOrgIDForBidInput } from 'app/modules/catalog-manager/queries/get-flight-schedules-by-org-id.query';
import { FlightScheduleService } from 'app/modules/catalog-manager/services/flight-schedule.service';
import { VesselScheduleService } from 'app/modules/catalog-manager/services/vessel-schedule.service';
import { LocationPair } from 'app/modules/contract-management/models/long-term-contract/location-pair.model';
import { ServiceTypes } from 'app/modules/wizard/enums/service-types.enum';
import { catchError, forkJoin, map, Observable, of, take } from 'rxjs';
import {
  GET_VESSEL_SCHEDULES_WITH_FILTER,
  GetVesselSchedulesWithFilterInput,
  GetVesselSchedulesWithFilterQueryResponse,
} from '../queries/get-filtered-vessel-schedules.query';

// Used only here therefore new model file is not required
interface SetSchedulesForLocationPairParams {
  locationPair: LocationPair;
  serviceId: ServiceTypes;
  currencyCode?: string;
  startDate: Date;
  endDate: Date;
}

@Injectable({
  providedIn: 'root',
})
export class BidScheduleService {
  private readonly notificationService = inject(NotificationService);
  private readonly vesselScheduleService = inject(VesselScheduleService);
  private readonly flightScheduleService = inject(FlightScheduleService);
  private readonly store = inject(Store);
  private readonly apollo = inject(Apollo);

  private readonly LIMIT = 1000; // There's no different API to get the data for the bid hence we're using the default limit

  setSchedulesForLocationPair(params: SetSchedulesForLocationPairParams): Observable<LocationPair> {
    const { locationPair, serviceId, currencyCode } = params;
    const fromLocationId = locationPair.originLocation.locationId;
    const toLocationId = locationPair.destinationLocation.locationId;

    if (serviceId === ServiceTypes.OCEAN_FREIGHT) {
      const hasFCL = locationPair.shipmentMethodsData.FCL?.containerTypes?.length > 0;
      const hasLCL = locationPair.shipmentMethodsData.LCL?.volumeWeights?.length > 0;

      if (!hasFCL && !hasLCL) {
        return of(locationPair);
      }

      const scheduleObservables: Observable<any>[] = [];

      if (hasFCL) {
        scheduleObservables.push(
          this.getVesselSchedulesByOrgIdForLocationPair({
            fromLocationId,
            toLocationId,
            shipmentModes: [LoadType.FCL],
            containerTypeIds: locationPair.shipmentMethodsData.FCL?.containerTypes.map(
              containerType => containerType._id
            ),
            currencyCode,
            startDate: params.startDate,
            endDate: params.endDate,
          }).pipe(
            map(schedules => ({
              type: LoadType.FCL,
              schedules,
            }))
          )
        );
      }

      if (hasLCL) {
        scheduleObservables.push(
          this.getVesselSchedulesByOrgIdForLocationPair({
            fromLocationId,
            toLocationId,
            shipmentModes: [LoadType.LCL],
            currencyCode,
            startDate: params.startDate,
            endDate: params.endDate,
            // volumeWeights: locationPair.shipmentMethodsData.LCL?.volumeWeights,
          }).pipe(
            map(schedules => ({
              type: LoadType.LCL,
              schedules,
            }))
          )
        );
      }

      return forkJoin(scheduleObservables).pipe(
        map(results => {
          const updatedLocationPair = { ...locationPair };
          updatedLocationPair.vesselSchedules = {
            [LoadType.LCL]: { schedule: [] },
            [LoadType.FCL]: { schedule: [] },
          };

          results.forEach(result => {
            if (result.type === LoadType.FCL) {
              updatedLocationPair.vesselSchedules[LoadType.FCL] = {
                schedule: result.schedules,
              };
            } else {
              updatedLocationPair.vesselSchedules[LoadType.LCL] = {
                schedule: result.schedules,
              };
            }
          });

          return updatedLocationPair;
        })
      );
    }

    if (serviceId === ServiceTypes.AIR_FREIGHT) {
      return this.getFlightSchedulesByOrgIdForLocationPair(
        fromLocationId,
        toLocationId,
        currencyCode,
        params.startDate,
        params.endDate
      ).pipe(
        map(schedules => ({
          ...locationPair,
          flightSchedules: {
            schedule: schedules,
          },
        }))
      );
    }

    return of(locationPair);
  }

  private getFlightSchedulesByOrgIdForLocationPair(
    fromLocationId: string,
    toLocationId: string,
    currencyCode?: string,
    startDate?: Date,
    endDate?: Date
  ) {
    const orgId = this.store.selectSnapshot(AuthState.getOrgId);
    console.log(startDate, endDate);
    return this.flightScheduleService
      .getFlightSchedulesByOrgId({
        orgId,
        offset: DEFAULT_OFFSET,
        limit: this.LIMIT,
        from: fromLocationId,
        to: toLocationId,
        currencyCode,
        startDate: startDate,
        endDate: endDate,
      } as GetFlightSchedulesByOrgIDForBidInput)
      .pipe(
        take(1),
        map(result => result.items),
        catchError(_error => {
          this.notificationService.showError('Failed to get flight schedules');
          return of([]);
        })
      );
  }

  private getVesselSchedulesByOrgIdForLocationPair(
    params: GetVesselSchedulesWithFilterInput
  ): Observable<VesselSchedule[]> {
    return this.apollo
      .query<GetVesselSchedulesWithFilterQueryResponse>({
        query: GET_VESSEL_SCHEDULES_WITH_FILTER,
        variables: {
          input: params,
        },
      })
      .pipe(
        map(result => result.data.getVesselSchedulesWithFilter),
        catchError(_error => {
          this.notificationService.showError('Failed to get vessel schedules');
          return of([]);
        })
      );
  }
}
