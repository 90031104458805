import { GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY } from '@configs/constants';
import { Pageable } from '@shared/models/pageable.model';
import { gql } from 'apollo-angular';
import { Organization } from '../models/organization.model';

// TODO: ADD OTHER NECESSARY FIELDS AS WELL
export const GET_PAGINATED_CHILDREN_ORGANIZATIONS_QUERY = gql`
  query GetPaginatedChildOrganizations($getPaginatedChildOrganizationsInput: GetPaginatedChildOrganizationsDto!) {
    getPaginatedChildrenOrganizations(getPaginatedChildOrganizationsInput: $getPaginatedChildOrganizationsInput) {
      items {
        ${GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY}
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        pageSize
        total
      }
    }
  }
`;

export type GetPaginatedChildrenOrganizationsDTO = {
  getPaginatedChildrenOrganizations: Pageable<Organization>;
};
