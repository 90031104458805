<div class="add-new-ocean-freight-service-catalog-item-container">
  <div class="header">
    <div class="close-button">
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <span class="title">Rate Structure</span>
  </div>
  <div class="content">
    <hmt-carrier-schedule-lcl-charging-structure
      #lclForm
      [doesRateStructureExist]="doesRateStructureExist"
      [isAirFreight]="true"
      [isVisible]="true"
      [isDisabled]="isDisabled"
      [isReadOnly]="isReadOnly" />
  </div>
  <div class="expander"></div>
  @if (!isReadOnly) {
    <div class="footer">
      <button mat-stroked-button class="btn-base btn-red-outline" (click)="close()">Cancel</button>
      <button mat-flat-button color="primary" class="save" (click)="onSubmit()" [disabled]="isDisabled">Submit</button>
    </div>
  }
</div>
