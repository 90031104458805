import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'hmt-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;
  confirmButtonText: string;
  showCancel: boolean;
  success: boolean;
  warning: boolean;

  @Input() confirmButtonCallback: () => void;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ConfirmationDialogData
  ) {
    this.title = data.title;
    this.message = this.formatMessage(data.message);
    this.confirmButtonText = data.confirmButtonText || 'Yes';
    this.showCancel = data.showCancel || false;
    this.success = data.success || false;
    this.warning = data.warning || false;
    this.confirmButtonCallback = data.onConfirmCallback || (() => {});
  }

  onConfirm() {
    if (this.confirmButtonCallback) {
      this.confirmButtonCallback();
    }
    this.dialogRef.close(true);
  }

  formatMessage(message: string): string {
    if (!message) {
      return '';
    }
    return message.replace(/{([^}]+)}/g, '<strong>$1</strong>');
  }
}
export interface ConfirmationDialogData {
  title: string;
  message: string;
  confirmButtonText?: string;
  showCancel?: boolean;
  success?: boolean;
  warning?: boolean;
  onConfirmCallback?: () => void;
}
