import { gql } from 'apollo-angular';
import { Operation } from '../models/operation.model';

export const SAVE_OPERATIONS_QUERY = gql`
  mutation SaveOperation($operationInput: OperationDto!) {
    saveOperation(operationInput: $operationInput) {
      _id
      belongsToOrgId
      createdBy
      name
      orgPartnerId
      updatedBy
      description
      deleted
    }
  }
`;

export type SaveOperationDTO = {
  saveOperation: Operation;
};
