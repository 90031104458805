import { gql } from 'apollo-angular';

export const GET_ORGANIZATION_VERTICALS_BY_ID = gql`
  query GetOrganizationById($orgId: String!) {
    org: getOrganizationById(orgId: $orgId) {
      verticals
    }
  }
`;

export interface GetOrganizationVerticalsByIdResponse {
  org: {
    verticals: string[];
  };
}
