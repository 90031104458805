<div class="rates-charging-structure--lcl">
  <div class="rates-table-wrapper" [ngClass]="maxWidth()">
    <div class="rate-table-container" [ngClass]="styles()">
      <table
        mat-table
        [dataSource]="[{ volumeWeight: 'Rate Per ' + weightUnitBasedOnFreightType }]"
        class="rate-table"
        #ratesTable>
        <ng-container matColumnDef="volumeWeight">
          <th mat-header-cell *matHeaderCellDef>{{ measurementTypeLabel }}</th>
          <td mat-cell *matCellDef="let row">{{ row.volumeWeight }}</td>
        </ng-container>

        <ng-container *ngFor="let column of columnsFormArray.controls; let i = index" [matColumnDef]="'weight_' + i">
          <th mat-header-cell *matHeaderCellDef [formGroup]="column">
            @if (hasOnlyZeroWeight) {
              <div class="weight-column">For Any {{ isAirFreight() ? 'KG' : 'CBM' }}</div>
            } @else {
              <div class="weight-column">
                @if (isReadOnly() || (!isAirFreight() && i === 0)) {
                  <input
                    formControlName="comparison"
                    type="text"
                    class="comparison-readonly"
                    [readonly]="isReadOnly()" />
                } @else {
                  <select class="no-arrow" formControlName="comparison" [disabled]="isReadOnly()">
                    <option value="<">&lt;</option>
                    <option value=">">&gt;</option>
                  </select>
                }
                <input
                  formControlName="value"
                  min="0"
                  type="number"
                  class="editable-cell weight-input"
                  [readonly]="isReadOnly() || (!isAirFreight() && i === 0)" />
                <span>{{ weightUnitBasedOnFreightType }}</span>
                @if (!isReadOnly() || (!isAirFreight() && i > 0)) {
                  <button mat-icon-button class="delete-button" (click)="removeColumn(i)" [disabled]="isReadOnly()">
                    <mat-icon>close</mat-icon>
                  </button>
                }
              </div>
            }
          </th>
          <td mat-cell *matCellDef="let row">
            <input
              type="number"
              min="0"
              [formControl]="ratesFormArray.controls[i].controls['rate']"
              class="editable-cell"
              [readonly]="isReadOnly()" />
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedWeightColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedWeightColumns"></tr>
      </table>
    </div>
    @if (!isReadOnly()) {
      <div class="add-button-container">
        <button color="primary" (click)="addColumn()" [disabled]="isReadOnly()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    }
  </div>
</div>
