import { gql } from 'apollo-angular';
import { OrgDocuments } from '../models/org-documents.model';

export const CREATE_ORG_DOCUMENT_CATEGORY_QUERY = gql`
  mutation CreateOrgDocumentCategory($createOrgDocumentCategoryInput: CreateOrgDocumentCategoryDto!) {
    createOrgDocumentCategory(createOrgDocumentCategoryInput: $createOrgDocumentCategoryInput) {
      _id
      createdAt
      createdBy
      orgId
      updatedAt
      updatedBy
      categories {
        _id
        createdAt
        createdBy
        deleted
        documents {
          customFileName
          fileId
          fileName
          fileType
          isActive
          isUploaded
          orgUserId
          uploadedDate
        }
        name
        updatedAt
        updatedBy
      }
    }
  }
`;

export interface CreateOrgDocumentCategoryDto {
  createOrgDocumentCategory: OrgDocuments;
}

export interface CreateOrgDocumentCategoryInputDto {
  name: string;
  orgId: string;
}
