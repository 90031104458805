import { gql } from 'apollo-angular';
import { Operation } from '../models/operation.model';

export const GET_OPERATIONS_OF_LOGGED_IN_USER_QUERY = gql`
  query GetOperationsOfLoggedInUser {
    getOperationsOfLoggedInUser {
      _id
      belongsToOrgId
      createdBy
      name
      orgPartnerId
      updatedBy
      description
    }
  }
`;

export type GetOperationsOfLoggedInUserDTO = {
  getOperationsOfLoggedInUser: Operation[];
};
