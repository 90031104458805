<div class="add-new-ocean-freight-service-catalog-item-container">
  <div class="header">
    <div class="close-button">
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <span class="title">Rate Structure</span>
  </div>
  <div class="content">
    @if (!isOnlyFCL && !isOnlyLCL) {
      <div class="shipment-method-selector">
        <mat-label>Shipment Method</mat-label>
        <mat-radio-group aria-label="Select shipment method" [formControl]="selectedShipmentMethod">
          <mat-radio-button value="FCL">FCL</mat-radio-button>
          <mat-radio-button value="LCL">LCL</mat-radio-button>
        </mat-radio-group>
      </div>
    } @else {
      <div class="empty-space"></div>
    }
    <hmt-carrier-schedule-fcl-charging-structure
      #fclForm
      [isVisible]="selectedShipmentMethod.value === 'FCL'"
      [isDisabled]="isDisabled"
      [isReadOnly]="isReadOnly" />
    <hmt-vessel-schedule-lcl-charging-structure
      #lclForm
      [isVisible]="selectedShipmentMethod.value === 'LCL'"
      [isDisabled]="isDisabled"
      [isReadOnly]="isReadOnly"
      [isAirFreight]="false" />
  </div>
  <div class="expander"></div>
  @if (!isReadOnly) {
    <div class="footer">
      <button mat-stroked-button class="btn-base btn-red-outline" (click)="close()">Cancel</button>
      <button mat-flat-button color="primary" class="save" (click)="onSubmit()" [disabled]="isDisabled">
        {{
          isDisabled ? 'Editing is disabled' : selectedShipmentMethod.value === 'FCL' ? 'Save for FCL' : 'Save for LCL'
        }}
      </button>
    </div>
  }
</div>
