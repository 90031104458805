import { LoadType } from '@shared/enums/load-type.enum';
import { gql } from 'apollo-angular';
import { VesselSchedule } from 'app/modules/catalog-manager/models/vessel-schedule.model';

export const GET_VESSEL_SCHEDULES_WITH_FILTER = gql`
  query GetVesselSchedulesWithFilter($input: VesselScheduleFilterInput!) {
    getVesselSchedulesWithFilter(input: $input) {
      _id
      cfsCutoff
      createdAt
      createdBy
      cyCutoffDate
      from
      fromEta
      fromEtd
      orgId
      previousPortOfCall
      previousPortOfCallEta
      previousPortOfCallEtd
      shippingLine
      to
      toEta
      toEtd
      totalTransitTime
      updatedAt
      updatedBy
      vesselName
      voyage
      routingDetails {
        type
        stops
      }
      versionHistory {
        _id
        changedField
        createdAt
        createdBy
        newValue
        oldValue
        orgUserId
        updatedAt
        updatedBy
      }
      _id
      cfsCutoff
      createdAt
      createdBy
      cyCutoffDate
      from
      fromEta
      fromEtd
      orgId
      previousPortOfCall
      previousPortOfCallEta
      previousPortOfCallEtd
      shippingLine
      to
      toEta
      toEtd
      totalTransitTime
      updatedAt
      updatedBy
      vesselName
      voyage
      rateStructure {
        fcl {
          _id
          contractedParty {
            _id
          }
          contractedPartyId
          createdAt
          createdBy
          operationId
          orgId
          rateCardType
          template
          updatedAt
          updatedBy
          rateCardData {
            _id
            additionalCharges {
              amount
              currencyCode
              description
            }
            clauses {
              clauseType
              name
              enabled
            }
            createdAt
            createdBy
            currencyCode
            name
            operationId
            orgId
            serviceType
            templateType
            updatedAt
            updatedBy
            data
          }
        }
        lcl {
          _id
          contractedParty {
            _id
          }
          contractedPartyId
          createdAt
          createdBy
          operationId
          orgId
          rateCardType
          template
          updatedAt
          updatedBy
          rateCardData {
            _id
            additionalCharges {
              amount
              currencyCode
              description
            }
            clauses {
              clauseType
              name
              enabled
            }
            createdAt
            createdBy
            currencyCode
            name
            operationId
            orgId
            serviceType
            templateType
            updatedAt
            updatedBy
            data
          }
        }
      }
    }
  }
`;

export interface GetVesselSchedulesWithFilterInput {
  fromLocationId: string;
  toLocationId: string;
  shipmentModes?: LoadType[];
  containerTypeIds?: string[];
  volumeWeights?: number[];
  currencyCode?: string;
  startDate: Date;
  endDate: Date;
}

export interface GetVesselSchedulesWithFilterQueryResponse {
  getVesselSchedulesWithFilter: VesselSchedule[];
}
