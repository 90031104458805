import { Component, computed, input, OnInit } from '@angular/core';

@Component({
  selector: 'hmt-shipment-method-block',
  templateUrl: './shipment-method-block.component.html',
  styleUrl: './shipment-method-block.component.scss',
})
export class ShipmentMethodBlockComponent implements OnInit {
  mode = input<string>('');
  containerTypes = input<(string | number)[]>([]);
  typeLabel = input<string>('');
  serviceType = input.required<'OCEAN-FREIGHT' | 'AIR-FREIGHT'>();

  isAirFreight = computed(() => {
    return this.serviceType() === 'AIR-FREIGHT';
  });

  isOceanFreight = computed(() => {
    return this.serviceType() === 'OCEAN-FREIGHT';
  });

  getTypeAsNumber = (type: string | number): number => {
    return type as number;
  };

  getTypeAsString = (type: string | number): string => {
    return type as string;
  };

  getAbsoluteValue = (value: number): number => {
    return Math.abs(value);
  };

  ngOnInit(): void {
    console.log('Service Type: ', this.serviceType());
  }
}
