import { GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY } from '@configs/constants';
import { gql } from 'apollo-angular';
import { Organization } from '../models/organization.model';

// TODO: ADD OTHER NECESSARY FIELDS AS WELL
export const GET_COMMON_ORGS_QUERY = gql`
  query GetCommonOrganizations {
    getCommonOrganizations {
      ${GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY}
    }
  }
`;

export type GetCommonOrganizationsResponse = {
  getCommonOrganizations: Organization[];
};
