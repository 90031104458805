import { gql } from 'apollo-angular';
import { OrgDocuments } from '../models/org-documents.model';

export const GET_ORGANIZATION_DOCUMENTS_QUERY = gql`
  query GetOrgDocuments($orgId: String!) {
    getOrgDocuments(orgId: $orgId) {
      _id
      createdAt
      createdBy
      orgId
      updatedAt
      updatedBy
      categories {
        _id
        createdAt
        createdBy
        deleted
        documents {
          customFileName
          fileId
          fileName
          fileType
          isActive
          isUploaded
          orgUserId
          uploadedDate
          uploadedByName
        }
        name
        updatedAt
        updatedBy
      }
    }
  }
`;

export interface GetOrganizationDocumentsDto {
  getOrgDocuments: OrgDocuments;
}
