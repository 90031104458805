import { gql } from 'apollo-angular';
import { VerticalInfo } from '../models/vertical-info.model';

export const GET_ALL_VERTICALS_QUERY = gql`
  query GetAllVerticals {
    getAllVerticals {
      _id
      createdBy
      purposes {
        description
        purposeId
      }
      updatedBy
      verticalDescription
      verticalId
    }
  }
`;

export type GetAllVerticalsResponseDTO = {
  getAllVerticals: VerticalInfo[];
};
