import { GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY } from '@configs/constants';
import { gql } from 'apollo-angular';
import { Organization } from '../models/organization.model';

export const SAVE_ORGANIZATION_QUERY = gql`
  mutation SaveOrganization($createOrganizationInput: CreateOrganizationDto!) {
    saveOrganization(createOrganizationInput: $createOrganizationInput) {
      ${GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY}
    }
  }
`;

export type SaveOrganizationDTO = {
  saveOrganization: Organization;
};
