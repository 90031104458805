import { SAVE_ORGANIZATION_QUERY, SaveOrganizationDTO } from './../queries/save-organization.query';
// TODO: REMOVE THIS ESLINT DISABLE COMMENT AND FIX ALL THE ENSUING LINT ERRORS
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API } from '@configs/api.config';
import { HolidaysCalendar } from '@shared/models/holidays-calendar.model';
import { Pageable } from '@shared/models/pageable.model';
import { Apollo } from 'apollo-angular';
import {
  GET_ORG_DETAILS_SUMMARY_BY_ID_QUERY,
  GetOrgDetailsSummaryByIdDResponse,
} from 'app/modules/task-feed/queries/get-org-details-summary-by-id.query';
import { map, Observable, take } from 'rxjs';
import { OrganizationType } from '../enums/organization-type.enum';
import { GeoFenceSpeedLimit } from '../models/geo-fence-speed-limit.model';
import { Operation } from '../models/operation.model';
import { Organization } from '../models/organization.model';
import {
  ADD_DOCUMENTS_TO_ORG_DOCUMENT_CATEGORY_QUERY,
  AddDocumentToOrgDocumentCategoryDto,
  AddDocumentToOrgDocumentCategoryInputDto,
} from '../queries/add-documents-to-org-document-category.query';
import {
  CHECK_ORG_SHORTNAME_AVAILABILITY_QUERY,
  CheckOrgShortNameAvailabilityDTO,
} from '../queries/check-org-shortname-availability.query';
import {
  CHECK_ORG_TICKER_CODE_AVAILABILITY_QUERY,
  CheckOrgTickerCodeAvailabilityDTO,
} from '../queries/check-org-ticker-code-availability.query';
import {
  CREATE_ORG_DOCUMENT_CATEGORY_QUERY,
  CreateOrgDocumentCategoryDto,
  CreateOrgDocumentCategoryInputDto,
} from '../queries/create-org-document-category.query';
import { DELETE_ORGANIZATION_QUERY, DeleteOrganizationResponseDto } from '../queries/delete-organization.query';
import {
  FIND_ORGANIZATIONS_BY_VERTICAL_ID_AND_MANAGED_BY_QUERY,
  FindOrganizationsByVerticalIdAndManagedByDTO,
} from '../queries/find-organizations-by-vertical-id-and-managed-by.query';
import { GET_ALL_VERTICALS_QUERY, GetAllVerticalsResponseDTO } from '../queries/get-all-verticals.query';
import {
  GET_CHILDREN_ORGANIZATIONS_QUERY,
  GetChildrenOrganizationsDTO,
} from '../queries/get-children-organizations.query';
import { GET_COMMON_ORGS_QUERY, GetCommonOrganizationsResponse } from '../queries/get-common-orgs.query';
import { GET_COUNTRY_BY_ID_QUERY, GetCountryByIdDTO } from '../queries/get-country-by-id.query';
import {
  GET_HOLIDAYS_BY_COUNTRY_AND_YEAR_QUERY,
  GetHolidaysByCountryAndYearDTO,
} from '../queries/get-holidays-by-country-and-year.query';
import { GetOnBoardedOrganizationsResponse } from '../queries/get-on-boarded-orgs.query';
import {
  GET_OPERATIONS_OF_LOGGED_IN_USER_QUERY,
  GetOperationsOfLoggedInUserDTO,
} from '../queries/get-operations-of-logged-in-user.query';
import { GET_ORGANIZATION_BY_ID_QUERY, GetOrganizationByIdDTO } from '../queries/get-organization-by-id.query';
import {
  GET_ORGANIZATION_DOCUMENTS_QUERY,
  GetOrganizationDocumentsDto,
} from '../queries/get-organization-documents.query';
import {
  GET_ORGANIZATION_NAME_BY_ID_QUERY,
  GetOrganizationNameByIdDTO,
} from '../queries/get-organization-name-by-id.query';
import {
  GET_ORGANIZATION_STRUCTURE_QUERY,
  GetOrganizationStructureDto,
} from '../queries/get-organization-structure.query';
import {
  GET_ORGANIZATION_VERTICALS_BY_ID,
  GetOrganizationVerticalsByIdResponse,
} from '../queries/get-organization-verticals-by-id.query';
import {
  GET_PAGINATED_CHILDREN_ORGANIZATIONS_QUERY,
  GetPaginatedChildrenOrganizationsDTO,
} from '../queries/get-paginated-children-organizations.query';
import {
  GET_PAGINATED_COMMON_ORGANIZATIONS_QUERY,
  GetPaginatedCommonOrganizationsDTO,
} from '../queries/get-paginated-common-organizations.query';
import {
  GET_PAGINATED_ON_BOARDED_ORGANIZATIONS_QUERY,
  GetPaginatedOnBoardedOrganizationsDTO,
} from '../queries/get-paginated-on-boarded-organizations.query';
import {
  GET_PAGINATED_PARTNER_ORGANIZATIONS_QUERY,
  GetPaginatedPartnerOrganizationsDTO,
} from '../queries/get-paginated-partner-organizations.query';
import { GET_PARTNER_ORGANIZATION_QUERY, GetPartnerOrganizationDTO } from '../queries/get-partner-organization.query';
import {
  GET_PARTNER_ORGANIZATIONS_BY_OPERATIONS_QUERY,
  GetPartnerOrganizationsByOperationsDTO,
} from '../queries/get-partner-organizations-by-operations.query';
import {
  REMOVE_DOCUMENT_FROM_ORG_DOCUMENT_CATEGORY_QUERY,
  RemoveDocumentFromOrgDocumentCategoryDto,
  RemoveDocumentFromOrgDocumentCategoryInputDto,
} from '../queries/remove-document-from-org-dcoument-category.query';
import {
  REMOVE_ORG_DOCUMENT_CATEGORY_QUERY,
  RemoveOrgDocumentCategoryInputDto,
} from '../queries/remove-org-document-category.query';
import { SAVE_OPERATIONS_QUERY, SaveOperationDTO } from '../queries/save-operation.query';
// import { GET_ORGANIZATION_BY_ID_QUERY, GetOrganizationByIdDTO } from '../queries/get-organization-by-id.query';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private httpClient = inject(HttpClient);
  private gqlService = inject(Apollo);

  getOrganizationStructure() {
    return this.gqlService
      .query<GetOrganizationStructureDto>({
        query: GET_ORGANIZATION_STRUCTURE_QUERY,
      })
      .pipe(
        take(1),
        map(result => result.data.getOrganizationStructure)
      );
  }

  saveOrganization(organization: Organization) {
    // TODO: MIGRATE TO USE GRAPHQL AND NEW SERVICES

    return this.gqlService
      .mutate<SaveOrganizationDTO>({
        mutation: SAVE_ORGANIZATION_QUERY,
        variables: {
          createOrganizationInput: organization,
        },
      })
      .pipe(
        take(1),
        map(result => result.data.saveOrganization)
      );
  }

  addResourceBulk(file: File): Observable<any> {
    // TODO: MIGRATE TO USE GRAPHQL AND NEW SERVICES
    const data = new FormData();
    data.append('file', file, file.name);
    const url = API.organization.bulkUpload;
    return this.httpClient.post<any>(url, data, {
      responseType: 'blob' as 'json',
    });
  }

  getOrganization(id: string) {
    return this.gqlService
      .query<GetOrganizationByIdDTO>({
        query: GET_ORGANIZATION_BY_ID_QUERY,
        variables: {
          orgId: id,
        },
      })
      .pipe(
        map(result => {
          return result.data.getOrganizationById;
        })
      );
  }

  getOrganizationNameById(id: string) {
    return this.gqlService
      .query<GetOrganizationNameByIdDTO>({
        query: GET_ORGANIZATION_NAME_BY_ID_QUERY,
        variables: {
          orgId: id,
        },
      })
      .pipe(
        map(result => {
          return result.data.getOrganizationById.orgName;
        })
      );
  }

  // TODO: Check implementation of this method
  getSelfOrganization() {
    return this.gqlService
      .query<GetPartnerOrganizationDTO>({
        query: GET_PARTNER_ORGANIZATION_QUERY,
      })
      .pipe(
        map(result => {
          return result.data.getSelfOrganization;
        })
      );
  }

  /*getPartnerOrgs(): Observable<Organization[]> {
    const url = API.organization.getPartnerOrganizations;
    return this.httpClient.get<Organization[]>(url);
  }*/
  // TODO: Check implementation of this method
  getPartnerOrgs() {
    return this.gqlService
      .query<GetPartnerOrganizationsByOperationsDTO>({
        query: GET_PARTNER_ORGANIZATIONS_BY_OPERATIONS_QUERY,
      })
      .pipe(
        map(result => {
          return result.data.getPartnerOrganizationsByOperations;
        })
      );
  }

  getPaginatedPartnerOrgs(searchText: string, pageNum: number, pageSize: number): Observable<Pageable<Organization>> {
    const params: {
      searchKey?: string;
      pageNum?: number;
      pageSize?: number;
    } = {};
    if (searchText != null && searchText.length !== 0) {
      params.searchKey = searchText;
    }

    if (pageNum != null) {
      params.pageNum = pageNum;
    }

    if (pageSize != null) {
      params.pageSize = pageSize;
    }

    return this.gqlService
      .query<GetPaginatedPartnerOrganizationsDTO>({
        query: GET_PAGINATED_PARTNER_ORGANIZATIONS_QUERY,
        variables: {
          getPaginatedPartnerOrganizationsInput: params,
        },
      })
      .pipe(
        take(1),
        map(result => {
          return result.data.getPaginatedPartnerOrganizations;
        })
      );
  }

  getCommonOrgs() {
    return this.gqlService
      .query<GetCommonOrganizationsResponse>({
        query: GET_COMMON_ORGS_QUERY,
      })
      .pipe(
        take(1),
        map(result => {
          return result.data.getCommonOrganizations;
        })
      );
  }

  getPaginatedCommonOrgs(searchText: string, pageNum: number, pageSize: number) {
    const params: {
      searchKey?: string;
      pageNum?: number;
      pageSize?: number;
    } = {};
    if (searchText != null || searchText != '') {
      params.searchKey = searchText;
    }

    if (pageNum != null) {
      params.pageNum = pageNum;
    }

    if (pageSize != null) {
      params.pageSize = pageSize;
    }

    return this.gqlService
      .query<GetPaginatedCommonOrganizationsDTO>({
        query: GET_PAGINATED_COMMON_ORGANIZATIONS_QUERY,
        variables: {
          getPaginatedCommonOrganizationsInput: params,
        },
      })
      .pipe(
        take(1),
        map(result => {
          return result.data.getPaginatedCommonOrganizations;
        })
      );
  }

  getOnBoardedOrgs(): Observable<Organization[]> {
    return this.gqlService
      .query<GetOnBoardedOrganizationsResponse>({
        query: GET_PAGINATED_ON_BOARDED_ORGANIZATIONS_QUERY,
      })
      .pipe(
        take(1),
        map(result => {
          return result.data.getOnBoardedOrganizations;
        })
      );
  }

  getPaginatedOnBoardedOrgs(searchText: string, pageNum: number, pageSize: number): Observable<Pageable<Organization>> {
    const params: {
      searchKey?: string;
      pageNum?: number;
      pageSize?: number;
    } = {};
    if (searchText != null || searchText != '') {
      params.searchKey = searchText;
    }

    if (pageNum != null) {
      params.pageNum = pageNum;
    }

    if (pageSize != null) {
      params.pageSize = pageSize;
    }

    return this.gqlService
      .query<GetPaginatedOnBoardedOrganizationsDTO>({
        query: GET_PAGINATED_ON_BOARDED_ORGANIZATIONS_QUERY,
        variables: {
          getPaginatedOnBoardedOrganizationsInput: params,
        },
      })
      .pipe(
        take(1),
        map(result => {
          return result.data.getPaginatedOnBoardedOrganizations;
        })
      );
  }

  // TODO: CHECK FUNCTIONALITY OF THIS METHOD
  getChildrenOrganizations(parentOrgId: string) {
    return this.gqlService
      .query<GetChildrenOrganizationsDTO>({
        query: GET_CHILDREN_ORGANIZATIONS_QUERY,
        variables: {
          getChildrenOrganizationsInput: {
            parentOrgId,
          },
        },
      })
      .pipe(
        take(1),
        map(result => {
          return result.data.getChildrenOrganizations;
        })
      );
  }

  getPaginatedChildrenOrganizations({
    pageNum,
    pageSize,
    parentOrgId,
    searchText,
  }: {
    parentOrgId: string;
    searchText?: string;
    pageNum?: number;
    pageSize?: number;
  }) {
    const params: {
      parentOrgId: string;
      searchKey?: string;
      pageNum?: number;
      pageSize?: number;
    } = {
      parentOrgId: parentOrgId,
    };
    if (searchText && searchText != '') {
      params.searchKey = searchText;
    }

    if (pageNum != null || pageNum != undefined) {
      params.pageNum = pageNum;
    }

    if (pageSize != null || pageSize != undefined) {
      params.pageSize = pageSize;
    }

    return this.gqlService
      .query<GetPaginatedChildrenOrganizationsDTO>({
        query: GET_PAGINATED_CHILDREN_ORGANIZATIONS_QUERY,
        variables: {
          getPaginatedChildOrganizationsInput: params,
        },
      })
      .pipe(
        take(1),
        map(result => {
          return result.data.getPaginatedChildrenOrganizations;
        })
      );
  }

  getOrganizationsByVertical(vertical: string, searchText: string) {
    const params: Record<string, string> = {
      verticalId: vertical,
    };

    if (searchText != null || searchText != '') {
      params['searchKey'] = searchText;
    }
    return this.gqlService
      .query<FindOrganizationsByVerticalIdAndManagedByDTO>({
        query: FIND_ORGANIZATIONS_BY_VERTICAL_ID_AND_MANAGED_BY_QUERY,
        variables: {
          findOrganizationsByVerticalIdAndManagedByInput: params,
        },
      })
      .pipe(
        take(1),
        map(result => {
          console.log(`result`, result);
          return result.data.findOrganizationsByVerticalIdAndManagedBy;
        })
      );
  }

  deleteOrganization(orgId: string) {
    return this.gqlService
      .query<DeleteOrganizationResponseDto>({
        query: DELETE_ORGANIZATION_QUERY,
        variables: {
          orgId,
        },
      })
      .pipe(
        take(1),
        map(result => {
          return result.data.deleteOrganization;
        })
      );
  }

  // TODO: CHECK WHY THIS ROUTE IS NOT IN MS_ORG_MANAGER
  getOrgsByTypeAndId(orgId: string, type: OrganizationType): Observable<Organization[]> {
    const url = API.organization.getOrgsByTypeAndId.format([orgId, type.toString()]);
    return this.httpClient.get<Organization[]>(url);
  }

  getVerticalInfos() {
    return this.getAllVerticals();
  }

  // TODO: CHANGE THIS IMPLEMENTATION
  fetchCurrencies(): Observable<string[]> {
    const url = API.organization.getCurrencies;
    return this.httpClient.get<string[]>(url);
  }

  fetchCountryById(countryId: string) {
    return this.gqlService
      .query<GetCountryByIdDTO>({
        query: GET_COUNTRY_BY_ID_QUERY,
        variables: {
          countryId,
        },
      })
      .pipe(
        take(1),
        map(result => {
          return result.data.getCountryById;
        })
      );
  }

  getHolidaysByCountryAndYear(countryCode: string, year: number): Observable<HolidaysCalendar | null> {
    return this.gqlService
      .query<GetHolidaysByCountryAndYearDTO>({
        query: GET_HOLIDAYS_BY_COUNTRY_AND_YEAR_QUERY,
        variables: {
          countryCode,
          year,
        },
      })
      .pipe(
        take(1),
        map(result => {
          return result.data.getHolidaysByCountryAndYear;
        })
      );
  }

  checkOrgShortNameAvailability(shortName: string) {
    return this.gqlService
      .query<CheckOrgShortNameAvailabilityDTO>({
        query: CHECK_ORG_SHORTNAME_AVAILABILITY_QUERY,
        variables: {
          shortName,
        },
      })
      .pipe(map(result => !result.data.checkIfOrgShortNameExists));
  }

  checkTickerCodeAvailability(tickerCode: string) {
    return this.gqlService
      .query<CheckOrgTickerCodeAvailabilityDTO>({
        query: CHECK_ORG_TICKER_CODE_AVAILABILITY_QUERY,
        variables: {
          tickerCode,
        },
      })
      .pipe(map(result => !result.data.checkIfTickerCodeExists));
  }

  // TODO: CHECK IF THIS IS NEEDED
  getOrganizationsByOrgIdAndOrgType(orgId: string, orgType: string, searchKey: string): Observable<Organization[]> {
    const url = API.organization.getOrganizationsByOrgIdAndOrgType.format([orgId, orgType]);
    let params = new HttpParams();
    params = params.append('searchKey', String(searchKey));
    return this.httpClient.get<Organization[]>(url, { params });
  }

  // TODO: CHECK THE FUNCTIONALITY
  getOperationsOfLoggedInUser() {
    return this.gqlService
      .query<GetOperationsOfLoggedInUserDTO>({
        query: GET_OPERATIONS_OF_LOGGED_IN_USER_QUERY,
      })
      .pipe(
        take(1),
        map(result => {
          return result.data.getOperationsOfLoggedInUser;
        })
      );
  }

  // TODO: NOT NEEDED TO MIGRATE YET
  getOperationSpeedLimits(operationId: string, searchString: string): Observable<GeoFenceSpeedLimit[]> {
    const url = API.organization.getOperationSpeedLimits.format([operationId]);
    return this.httpClient.get<GeoFenceSpeedLimit[]>(url, { params: { searchKey: searchString } });
  }

  // TODO: NOT NEEDED TO MIGRATE YET
  saveOperationSpeedLimit(speedLimit: GeoFenceSpeedLimit): Observable<GeoFenceSpeedLimit> {
    const url = API.organization.saveOperationSpeedLimits.format([speedLimit.operationId]);
    return this.httpClient.post<GeoFenceSpeedLimit>(url, speedLimit);
  }

  // TODO: NOT NEEDED TO MIGRATE YET
  deleteOperationSpeedLimit(speedLimit: GeoFenceSpeedLimit): Observable<GeoFenceSpeedLimit> {
    const url = API.organization.deleteOperationSpeedLimits.format([speedLimit.operationId, speedLimit.id]);
    return this.httpClient.delete<GeoFenceSpeedLimit>(url);
  }

  saveOperation(operation: Operation) {
    return this.gqlService
      .mutate<SaveOperationDTO>({
        mutation: SAVE_OPERATIONS_QUERY,
        variables: {
          operationInput: operation,
        },
      })
      .pipe(
        take(1),
        map(result => result.data.saveOperation)
      );
  }

  // TODO: REIMPLEMENT THIS LATER CORRECTLY
  getOrgPartnerOrgIds(): Observable<string[]> {
    const url = API.organization.getOrgIdsByOrgType;
    let params = new HttpParams();
    params = params.append('orgTypes', String('ORG_PARTNER'));
    return this.httpClient.get<string[]>(url, { params });
  }

  getAllVerticals() {
    return this.gqlService
      .query<GetAllVerticalsResponseDTO>({
        query: GET_ALL_VERTICALS_QUERY,
      })
      .pipe(
        map(result => {
          return result.data.getAllVerticals;
        })
      );
  }

  getOrganizationDocuments(orgId: string) {
    return this.gqlService
      .query<GetOrganizationDocumentsDto>({
        query: GET_ORGANIZATION_DOCUMENTS_QUERY,
        variables: {
          orgId,
        },
      })
      .pipe(map(result => result.data.getOrgDocuments));
  }

  createOrgDocumentCategory({ name, orgId }: CreateOrgDocumentCategoryInputDto) {
    return this.gqlService
      .mutate<CreateOrgDocumentCategoryDto>({
        mutation: CREATE_ORG_DOCUMENT_CATEGORY_QUERY,
        variables: {
          createOrgDocumentCategoryInput: {
            name,
            orgId,
          },
        },
      })
      .pipe(map(result => result.data.createOrgDocumentCategory));
  }

  addDocumentToOrgDocumentCategory({
    addDocumentToOrgDocumentCategoryInput,
  }: AddDocumentToOrgDocumentCategoryInputDto) {
    return this.gqlService
      .mutate<AddDocumentToOrgDocumentCategoryDto>({
        mutation: ADD_DOCUMENTS_TO_ORG_DOCUMENT_CATEGORY_QUERY,
        variables: {
          addDocumentToOrgDocumentCategoryInput,
        },
      })
      .pipe(map(result => result.data.addDocumentToOrgDocumentCategory));
  }

  removeDocumentFromOrgDocumentCategory({
    removeDocumentFromOrgDocumentCategoryInput,
  }: RemoveDocumentFromOrgDocumentCategoryInputDto) {
    return this.gqlService
      .mutate<RemoveDocumentFromOrgDocumentCategoryDto>({
        mutation: REMOVE_DOCUMENT_FROM_ORG_DOCUMENT_CATEGORY_QUERY,
        variables: {
          removeDocumentFromOrgDocumentCategoryInput,
        },
      })
      .pipe(map(result => result.data.removeDocumentFromOrgDocumentCategory));
  }

  removeOrgDocumentCategory({ removeOrgDocumentCategoryInput }: RemoveOrgDocumentCategoryInputDto) {
    return this.gqlService
      .mutate({
        mutation: REMOVE_ORG_DOCUMENT_CATEGORY_QUERY,
        variables: { removeOrgDocumentCategoryInput },
      })
      .pipe(map(result => result.data.removeOrgDocumentCategory));
  }

  getOrgDetailsSummaryById(orgId: string) {
    return this.gqlService
      .query<GetOrgDetailsSummaryByIdDResponse>({
        query: GET_ORG_DETAILS_SUMMARY_BY_ID_QUERY,
        variables: { orgId },
      })
      .pipe(map(result => result.data.getOrganizationById));
  }

  getOrganizationVerticalsById(orgId: string) {
    return this.gqlService
      .query<GetOrganizationVerticalsByIdResponse>({
        query: GET_ORGANIZATION_VERTICALS_BY_ID,
        variables: { orgId },
      })
      .pipe(map(result => result.data.org.verticals));
  }
}
