<div class="shipment-method-requirement-container">
  <div class="mode">{{ mode() }}</div>
  <mat-divider></mat-divider>
  <div class="bold type">{{ typeLabel() }}</div>
  <div class="requirement-blocks-container">
    @for (type of containerTypes(); track type) {
      @if (mode() === 'FCL') {
        <hmt-key-value [keyValue]="{ key: '', value: getTypeAsString(type) }" style="width: 100%"></hmt-key-value>
      }
      @if (mode() === 'LCL') {
        <div class="lcl-requirement-block">
          <!-- @if ($index) {} -->
          @if (containerTypes().length > 1) {
            <span class="operator">{{ getTypeAsNumber(type) >= 0 ? '>' : '<' }}</span>
            <p class="requirement-block-value">
              {{ getAbsoluteValue(getTypeAsNumber(type)) }}
              @if (serviceType() === 'OCEAN-FREIGHT') {
                <span>CBM</span>
              } @else if (serviceType() === 'AIR-FREIGHT') {
                <span>Kg</span>
              }
            </p>
          } @else {
            <span>For any {{ serviceType() === 'OCEAN-FREIGHT' ? 'CBM' : 'KG' }}</span>
          }
        </div>
      }
    }
  </div>
</div>
