import { gql } from 'apollo-angular';

// TODO: ADD OTHER NECESSARY FIELDS AS WELL
export const DELETE_ORGANIZATION_QUERY = gql`
  mutation DeleteOrganization($orgId: String!) {
    deleteOrganization(orgId: $orgId) {
      success
    }
  }
`;

export type DeleteOrganizationResponseDto = {
  deleteOrganization: {
    success: boolean;
  };
};
