import { GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY } from '@configs/constants';
import { gql } from 'apollo-angular';
import { Organization } from '../models/organization.model';

// TODO: ADD OTHER NECESSARY FIELDS AS WELL
export const GET_PARTNER_ORGANIZATIONS_BY_OPERATIONS_QUERY = gql`
  query GetPartnerOrganizationsByOperations {
    getPartnerOrganizationsByOperations {
      ${GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY}
    }
  }
`;

export interface GetPartnerOrganizationsByOperationsDTO {
  getPartnerOrganizationsByOperations: Organization[];
}
